const setToken = (state, { token, validUntil }) => {
	state.token = token;
	state.validUntil = validUntil;
};

const setAuthUser = (state, payload) => {
	state.authUser = payload;
}

const clearToken = state => {
	state.token = null;
	state.validUntil = null;
};

const setUserPermissions = (state, value) => {
	state.userPermissions = value;
}

export default {
	setToken,
	setAuthUser,
	clearToken,
	setUserPermissions
};
