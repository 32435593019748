import AdminApi from "@/api/admin/roleGroups";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const errorHandling = new ErrorHandling();
//all is for select menu in create/edit auction
// todo get all data from backend when sending all:true param in request
const getItems = async ({ commit, state }) => {
	commit("setLoading", true);
	const options = JSON.parse(JSON.stringify(state.pagination));
	Object.keys(options).forEach(function() {
		options["pageNumber"] = options["page"];
		options["pageSize"] = options["itemsPerPage"];
		options["searchString"] = options["Name"];
		options["sortDesc"] = options["sortDesc"].toString();
		options["sortBy"] = options["sortBy"].toString();
	});
	try {
		const { data } = await adminApi.getList(options);
		commit("setItemsData", data);
		commit("setLoading", false);
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error, { pagination: options });
		throw new Error(error);
	}
};

const getAllItems = async ({ commit }) => {
	commit("setLoading", true);
	try {
		const { data } = await adminApi.getAllItems();
		commit("setItemsData", data);
		commit("setLoading", false);
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error);
		throw new Error(error);
	}
};

const setPagination = async ({ commit }, payload) => {
	commit("setPagination", payload);
};

const getItem = async ({ commit }, itemId) => {
	commit("setLoading", true);
	try {
		const { data } = await adminApi.getItem(itemId);
		commit("setItemData", data);
		commit("setLoading", false);
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error, { itemId });
		throw new Error(error);
	}
};

const insertItem = async ({ commit }, item) => {
	commit("setLoading", true);
	try {
		await adminApi.insertItem(item);
		commit("setLoading", false);
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error, { item });
		throw new Error(error);
	}
};

const updateItem = async ({ commit }, item) => {
	commit("setLoading", true);
	try {
		item.roleIds = item.roleIds.map(o => o["id"]);
		await adminApi.updateItem(item);
		commit("setLoading", false);
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error, { item });
		throw new Error(error);
	}
};

const deleteItem = async ({ commit }, item) => {
	commit("setLoading", true);
	try {
		await adminApi.deleteItem(item.id);
		commit("setLoading", false);
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error, { item });
		throw error;
	}
};

export default {
	getItems,
	getAllItems,
	getItem,
	setPagination,
	insertItem,
	updateItem,
	deleteItem
};
