var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"dense":_vm.dense,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"show-expand":"","item-key":"date","loading":_vm.loading},on:{"click:row":function($event){return _vm.click($event)}},scopedSlots:_vm._u([{key:"item.picture",fn:function(ref){
var item = ref.item;
return [_c('avatar',{staticClass:"mx-auto",attrs:{"user":item}})]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_c('moments-ago',{attrs:{"unix":true,"date":item.date}})]}},{key:"item.result",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.result === "OK" ? "OK" : _vm.readError(item.reason))+" ")]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-2",attrs:{"colspan":headers.length}},[_c('div',[_vm._v("First Name: "+_vm._s(item.firstName))]),_c('div',[_vm._v("Last Name: "+_vm._s(item.lastName))])])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }