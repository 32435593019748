import Vue from "vue";
const setItemsData = (state, value) => {
  state.items = value.result
    ? value.result.map((item, index) => {
        return { id: index + 1, domain: item };
      })
    : [];
  state.totalCount = value.pagination.totalCount;
};

const setLoading = (state, value) => {
  state.loading = value;
};

const setPagination = (state, value) => {
  Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

export default {
  setItemsData,
  setPagination,
  setLoading,
};
