<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <loader v-if="loading" />
        <t-form-wrapper
          v-else
          :form-data="formData"
          :callback-route="callbackRoute"
          :store-url="storeUrl"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    TFormWrapper: () => import("@/components/Form/TFormWrapper"),
    Loader: () => import("@/components/Core/Loader")
  },
  name: "EditRole",
  data() {
    return {
      formData: null,
      loading: true,
      storeUrl: "adminRoles/updateItem",
      callbackRoute: "list-role"
    };
  },
  computed: {
    ...mapGetters({
      role: "adminRoles/item",
      permissions: "adminPermissions/items"
    })
  },
  async mounted() {
    const { uid } = this.$route.params;
    await this.$store.dispatch("adminRoles/getItem", uid);
    await this.$store.dispatch("adminPermissions/getAllItems");
    this.formData = this.createFormData();
    this.loading = false;
  },
  methods: {
    createFormData() {
      const {
        id,
        roleName,
        roleDisplayName,
        roleDescription,
        permissions
      } = this.role;
      let formData = [
        {
          stepTitle: "General",
          stepFields: {
            id: {
              type: "text",
              disabled: true,
              value: id,
              placeholder: "Role ID",
              label: "ID",
              required: true
            },
            roleName: {
              type: "text",
              value: roleName,
              name: "roleName",
              placeholder: "Enter Role Name",
              label: "Role Name",
              required: true
            },
            roleDisplayName: {
              type: "text",
              value: roleDisplayName,
              name: "roleDisplayName",
              placeholder: "Enter Role Display Name",
              label: "Role Display Name",
              required: true
            },
            roleDescription: {
              type: "textarea",
              value: roleDescription,
              name: "roleDescription",
              placeholder: "Enter Role Description",
              label: "Role Description",
              required: true
            },
            permissionIds: {
              type: "select",
              value: permissions,
              items: this.permissions,
              itemValue: "id",
              itemText: "permissionDisplayName",
              name: "permissionIds",
              placeholder: "Select permissions",
              label: "Permissions",
              required: true,
              returnObject: true,
              multiple: true
            }
          }
        }
      ];
      return formData;
    }
  }
};
</script>

<style></style>
