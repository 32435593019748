import Vue from "vue";

const setItemsData = (state, value) => {
  state.items = value;
};

const setItemData = (state, value) => {
  state.item = value;
};

const setLoading = (state, data) => {
  state.loading[data.name] = data.value;
};

const setError = (state, data) => {
  state.error[data.name] = data.value;
};

const setPagination = (state, value) => {
  Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

const setUserActivity = (state, value) => {
  state.userActivity = value;
};

const setPrevNextPage = (state, value) => {
  state.pagination.hasPrevious = value.hasPrevious;
  state.pagination.hasNext = value.hasNext;
};

const setUserActivityTotalCount = (state, value) => {
  state.userActivityTotalCount = value;
};

const setUserActivityPagination = (state, value) => {
  Vue.set(
    state,
    "userActivityPagination",
    Object.assign(state.userActivityPagination, value)
  );
};

const setAllInterests = (state, data) => {
  state.allInterests = data;
};

const setUserInterests = (state, data) => {
  state.userInterests = data[0];
};
const setResultLength = (state, value) => {
  state.resultLength = value;
};

export default {
  setItemsData,
  setItemData,
  setPrevNextPage,
  setPagination,
  setLoading,
  setError,
  setUserActivity,
  setUserActivityTotalCount,
  setUserActivityPagination,
  setAllInterests,
  setUserInterests,
  setResultLength
};
