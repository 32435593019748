<template>
  <v-container fluid>
    <div class="d-flex flex-column fill-height flex-grow-1">
      <div class="d-flex">
        <div class="text-h4">Auction blocked users</div>
      </div>
      <div class="flex-grow-1">
        <div class="filters-wrapper d-flex flex-column flex-md-row py-4">
          <div class="date-wrapper d-flex flex-column flex-md-row align-center">
            <Datepicker
              :namespace="namespace"
              :initial-value="daysOffset('-')"
              :from-date="true"
            />
            <Datepicker
              :namespace="namespace"
              :initial-value="daysOffset('+')"
              :to-date="true"
            />
            <div class="reasons-wrapper mt-2">
              <v-select
                v-model="blockedBy"
                :items="blockedByOptions"
                outlined
                :attach="'#menu'"
                hide-details
                label="Blocked by"
                placeholder="Blocked by"
                @change="changeBlockedBy"
              ></v-select>
              <div id="menu"></div>
            </div>
          </div>
        </div>
        <t-table
          :headers="headers"
          :namespace="namespace"
          :permissions-namespace="namespace"
          :route-name="routeName"
          :id="idValue"
          :custom-action-btn="customActionBtn"
          @custom-action="unblockUser"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PostModeration",
  components: {
    TTable: () => import("@/components/Core/Table/TTable"),
    Datepicker: () => import("@/components/Core/Datepicker"),
  },
  data() {
    return {
      routeName: "auction-blocked-users",
      namespace: "auctionBlockedUsers",
      permissionsNamespace: "auction-blocked-users",
      idValue: "id",
      customActionBtn: {
        text: "Unblock",
        icon: "mdi-account-lock-open-outline",
      },
      headers: [
        {
          text: "Name",
          align: "left",
          value: "name",
          sortable: false,
        },
        {
          text: "User ID",
          align: "left",
          value: "userId",
          sortable: false,
        },
        {
          text: "Blocked until",
          align: "left",
          value: "blockedUntil",
          sortable: false,
        },
        {
          text: "Blocked by",
          align: "left",
          value: "blockedBy",
          sortable: false,
        },
        {
          text: "Reason",
          align: "left",
          value: "reason",
          sortable: false,
        },
        {
          text: "Unblock user",
          align: "right",
          value: "customAction",
          sortable: false,
        },
      ],
      dateFrom: "",
      dateTo: "",
      blockedBy: "",
      blockedByOptions: [
        { text: "Admin", value: "Admin" },
        { text: "Auction win", value: "AuctionWin" },
      ],
      getblockedUsersPayload: {
        SkipCount: "",
        MaxResultCount: "",
        BlockedBy: "",
        Name: "",
        StartDateFrom: "",
        StartDateTo: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      options: "auctionBlockedUsers/pagination",
    }),
    daysOffset() {
      return (action) => {
        const daysOffset = new Date(
          Date.now() + (action === "+" ? 62 : -7) * 24 * 60 * 60 * 1000
        );
        return daysOffset.toLocaleString("fr-CA", {
          year: "numeric",
          month: "numeric",
          day: "numeric",
        });
      };
    },
  },
  methods: {
    async unblockUser(item) {
      await this.$store.dispatch(
        "auctionBlockedUsers/unblockUser",
        item.userId
      );
      this.$store.dispatch(
        "auctionBlockedUsers/getItems",
        this.getblockedUsersPayload
      );
    },
    async changeBlockedBy() {
      this.$store.commit(
        "auctionBlockedUsers/setBlockedByType",
        this.blockedBy
      );
    },
  },
  beforeMount() {
    this.options.StartDateFrom = this.daysOffset("-");
    this.options.StartDateTo = this.daysOffset("+");
    this.$store.dispatch("auctionBlockedUsers/setPagination", this.options);
  },
};
</script>

<style lang="scss" scoped>
.reasons-wrapper {
  position: relative;
  width: 300px;
}

#menu {
  position: absolute;
  bottom: 0px;
}
.filters-wrapper {
  padding: 12px;
}
</style>
