import AdminApi from "@/api/admin/AdminApi";

export default class AuctionBlockedUsersApi extends AdminApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "auctadmwsvc/Auction/";
  }

  async getUserList(pagination) {
    let url = this.baseEndpoint + `GetBlockedUsers`;
    let response = await this.getData(url, pagination);
    return response;
  }

  async blockUser(payload) {
    let url = this.baseEndpoint + `BlockUser`;
    let response = await this.insertOrUpdateData(url, payload);
    return response;
  }

  async unblockUser(userId) {
    let url = this.baseEndpoint + `UnblockUser/${userId}`;
    let response = await this.insertOrUpdateData(url);
    return response;
  }
}
