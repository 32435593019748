<template>
  <div class="fill-height d-flex align-center justify-center">
    <loader v-if="loading" />
    <v-alert v-else prominent type="error">
      <v-row align="center">
        <v-col class="grow">
          Access denied. Contact the administrator.
        </v-col>
        <v-col class="shrink">
          <v-btn @click="logout">Log out</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </div>
</template>

<script>
import Loader from "@/components/Core/Loader";
export default {
  name: "Auth",
  components: {
    Loader,
  },
  data() {
    return {
      loading: true,
    };
  },
  async created() {
    const {
      params: { firstPageName, routeParams },
    } = this.$route;
    const { token, valid } = this.$route.query;
    if (token) {
      await this.$store.dispatch("auth/setToken", { token, valid });
    } else {
      await this.$store.dispatch("auth/loadLocalToken");
    }
    await this.$store.dispatch("auth/getAuthUser");
    const permissions = await this.$store.dispatch(
      "adminPermissions/getAuthUserPermissions"
    );
    if (!permissions) {
      this.loading = false;
      return;
    }
    this.$router.push({
      name: `${firstPageName ? firstPageName : "admin"}`,
      params: routeParams,
    });
  },
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<style></style>
