<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <t-form-wrapper
          v-if="auctionFormData"
          :form-data="auctionFormData"
          :callback-route="callbackRoute"
          :store-url="storeUrl"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    TFormWrapper: () => import("@/components/Form/TFormWrapper"),
  },
  name: "CreateAuction",
  data() {
    return {
      auctionFormData: null,
      storeUrl: "adminAuctions/insertItem",
      callbackRoute: "list-auction",
    };
  },
  computed: {
    ...mapGetters({
      products: "adminProducts/items",
      sponsors: "adminSponsors/items",
    }),
  },
  async mounted() {
    await this.$store.dispatch("adminProducts/getItems", true);
    await this.$store.dispatch("adminSponsors/getItems", true);
    this.auctionFormData = this.createFormData();
  },
  methods: {
    createFormData() {
      let formData = [
        {
          stepTitle: "General",
          stepFields: {
            name: {
              type: "text",
              value: "",
              name: "name",
              placeholder: "Enter Auction name",
              label: "Name",
              required: true,
            },
            startingBid: {
              type: "number",
              value: "",
              name: "startingBid",
              placeholder: "Enter Auction starting bid",
              label: "Auction starting bid",
              required: true,
              minValue: 1,
              isInteger: true,
            },
            description: {
              type: "textarea",
              value: "",
              name: "description",
              placeholder: "Enter Auction Description",
              label: "Description",
              required: true,
            },
            auctionProductId: {
              type: "select",
              value: "",
              name: "auctionProductId",
              items: this.products,
              itemValue: "auctionProductId",
              itemText: "name",
              placeholder: "Select product",
              label: "Select product",
              required: true,
            },
            auctionSponsorId: {
              type: "select",
              value: "",
              name: "auctionSponsorId",
              items: this.sponsors,
              itemValue: "auctionSponsorId",
              itemText: "name",
              placeholder: "Select sponsor",
              label: "Select sponsor",
              required: false,
            },
          },
        },
        {
          stepTitle: "Date and time",
          stepFields: {
            startDateTime: {
              type: "dateTime",
              label: "Auction start date and time",
              value: new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
              ).toISOString(),
              showTime: true,
              isRange: true,
              rangeLabel: "start",
            },
            endDateTime: {
              type: "dateTime",
              label: "Auction end date and time",
              value: new Date(
                new Date().getTime() - new Date().getTimezoneOffset() * 60000
              ).toISOString(),
              showTime: true,
              isRange: true,
              rangeLabel: "end",
              rangeValid: true,
            },
          },
        },
      ];
      return formData;
    },
  },
};
</script>

<style></style>
