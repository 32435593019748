export default {
  computed: {
    userPermissions() {
      return this.$store.getters["auth/userPermissions"];
    },
    permissionToUpdateAccountType() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "user-updateAccountType"
      );
    },
    permissionToBlockUser() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "user-block"
      );
    },
    permissionToViewUserActivity() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "user-viewActivity"
      );
    },
    permissionToUpdateUserRoles() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "user-updateRole"
      );
    },
    permissionToUpdateUserRoleGroups() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "user-updateRoleGroup"
      );
    },
    permissionToUpdateUserDonations() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "donations-update"
      );
    },
    permissionToViewUsers() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "user-list"
      );
    },
    permissionToViewSponsors() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "sponsor-list"
      );
    },
    permissionToViewPartners() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "partner-list"
      );
    },
    permissionToViewProducts() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "product-list"
      );
    },
    permissionToViewAuctions() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "auction-list"
      );
    },
    permissionToViewRoles() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "role-list"
      );
    },
    permissionToViewRoleGroups() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "roleGroup-list"
      );
    },
    permissionToViewItem() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === `${this.permissionsNamespace}-view`
      );
    },
    permissionToUpdateItem() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === `${this.permissionsNamespace}-update`
      );
    },
    permissionToDeleteItem() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === `${this.permissionsNamespace}-delete`
      );
    },
    permissionToCreateItem() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === `${this.permissionsNamespace}-create`
      );
    },
    permissionToGenerateReports() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "reporting-list"
      );
    },
    permissionToGenerateFraudUserReport() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "reporting-fraud-users"
      );
    },
    permissionToGenerateRegisteredUsersReport() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "reporting-registered-users"
      );
    },
    permissionToAccessDeleteSummaryReport() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "delete-summary-report"
      );
    },
    permissionToAccessDeletionProcessList() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "delete-process-list"
      );
    },
    permissionToRetriggerDeletionProcess() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "delete-retrigger-process"
      );
    },
    permissionToListBlockedDomains() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "domain-list"
      );
    },
    permissionToBlockDomain() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "domain-block"
      );
    },
    permissionToUnblockDomain() {
      return !!this.userPermissions.find(
        (item) => item.permissionName === "domain-unblock"
      );
    },
  },
};
