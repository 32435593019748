import AdminApi from "@/api/admin/AdminApi";

export default class AdminUsersApi extends AdminApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "identityadm/v1.0/admin/";
    this.accountTypesEndpoint = this.domain + "identityadm/v1.0/account-types";
    this.provisionEndpoint = this.domain + "provisionnew/v2/user/";
    this.gdprEndpoint = this.domain + "identityadm/v1.0/gdpr/";
  }

  async getList(pagination) {
    const url = `${this.baseEndpoint}users`;
    const response = await this.getData(url, pagination);
    return response;
  }

  async getUserActivity(pagination) {
    const url = `${this.baseEndpoint}users/activities`;
    const response = await this.getData(url, pagination);
    return response;
  }

  async getLatestSignups(clientId) {
    const url = `${this.baseEndpoint}users/latest/signups/${clientId}`;
    const response = await this.getData(url);
    return response;
  }

  async getLatestLogins(clientId) {
    const url = `${this.baseEndpoint}users/latest/logins/${clientId}`;
    const response = await this.getData(url);
    return response;
  }

  async getItem(id) {
    const url = `${this.baseEndpoint}users/${id}`;
    const response = await this.getData(url);
    return response;
  }

  async blockUser(id) {
    const url = `${this.baseEndpoint}users/${id}/block`;
    const response = await this.patchData(url);
    return response;
  }

  async activateUser(id) {
    const url = `${this.baseEndpoint}users/${id}/activate`;
    const response = await this.patchData(url);
    return response;
  }

  // TODO
  async deleteItem(id) {
    let url = this.baseEndpoint + "Users/" + id;
    let response = await this.deleteData(url, null, true);
    return response;
  }

  async getAccountTypes() {
    const url = this.accountTypesEndpoint;
    const response = await this.getData(url);
    return response;
  }

  async setAccountType(id, type) {
    const url = `${this.baseEndpoint}users/${id}/account/${type}`;
    const response = await this.patchData(url);
    return response;
  }

  async addRoleToUser(userId, roleId) {
    const url = `${this.baseEndpoint}users/${userId}/roles/${roleId}`;
    const response = await this.patchData(url);
    return response;
  }

  async removeRoleFromUser(userId, roleId) {
    const url = `${this.baseEndpoint}users/${userId}/roles/${roleId}`;
    const response = await this.deleteData(url, null, true);
    return response;
  }

  async addRoleGroupToUser(userId, roleGroupId) {
    const url = `${this.baseEndpoint}users/${userId}/roleGroups/${roleGroupId}`;
    const response = await this.patchData(url);
    return response;
  }

  async removeRoleGroupFromUser(userId, roleGroupId) {
    const url = `${this.baseEndpoint}users/${userId}/roleGroups/${roleGroupId}`;
    const response = await this.deleteData(url, null, true);
    return response;
  }

  async getAllInterests() {
    const url = `${this.provisionEndpoint}interests/list`;
    const response = await this.getData(url);
    return response;
  }

  async getUserInterests(id) {
    const url = this.baseEndpoint + "users/" + id + "/interests";
    const response = await this.getData(url);
    return response;
  }

  async setUserInterests(payload) {
    const url =
      this.baseEndpoint + "users/" + payload.userProfileId + "/interests";
    const response = await this.patchData(url, payload);
    return response;
  }

  async deleteUser(id) {
    let url = this.gdprEndpoint + id + "/user";
    let response = await this.deleteData(url, null, true);
    return response;
  }
}
