<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <t-form-wrapper
          v-if="formData"
          :form-data="formData"
          :callback-route="callbackRoute"
          :loading-prop="loading"
          @submit-form="handleSubmit"
        />
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar" color="red accent-2">
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  components: {
    TFormWrapper: () => import("@/components/Form/TFormWrapper"),
  },
  name: "CreateBlockedDomain",
  data() {
    return {
      formData: null,
      storeUrl: "adminBlockedDomains/insertItem",
      callbackRoute: "list-blocked-domain",
      snackbar: false,
      loading: false,
      text: `Domain length needs to be between 1 and 100 characters`,
    };
  },
  mounted() {
    this.formData = this.createFormData();
  },
  methods: {
    createFormData() {
      let formData = [
        {
          stepTitle: "General",
          stepFields: {
            name: {
              type: "text",
              value: "",
              name: "name",
              placeholder: "Enter domain",
              label: "Domain",
              required: true,
            },
          },
        },
      ];
      return formData;
    },
    async handleSubmit(e) {
      if (e.name.length <= 1 || e.name.length > 100) {
        this.snackbar = true;
        this.text = `Domain length needs to be between 1 and 100 characters`;
        return;
      }
      this.loading = true;
      await this.$store
        .dispatch(this.storeUrl, e)
        .then(() => {
          this.$router.push({ name: this.callbackRoute });
          this.$store.dispatch("snackbar/show", {
            text: "Domain successfully added",
          });
        })
        
        .catch((err) => {
          this.snackbar = true;
          this.text =
            err?.responseException?.exceptionMessage[0]?.errorMessage ||
            "Error";
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
