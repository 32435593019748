const items = (state) => state.items;
const item = (state) => state.item;
const loading = (state) => state.loading;
const modalOpened = (state) => state.modalOpened;
const pagination = (state) => state.pagination;
const totalCount = (state) => state.totalCount;

export default {
  items,
  item,
  loading,
  modalOpened,
  totalCount,
  pagination,
};
