import AdminApi from "@/api/admin/AdminApi";

export default class AdminBlockedDomainsApi extends AdminApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "identityadm/v1.0/admin/users/email-domains/";
	}

	async getList(pagination) {
		let url = this.baseEndpoint + "blacklist";
		let response = await this.getData(url, pagination);
		return response;
	}

	async insertItem(item) {
		let url = this.baseEndpoint + "blacklist/" + item.name;
		let response = await this.insertOrUpdateData(url);
		return response;
	}

	async unblockDomain(emailDomain) {
		let url = this.baseEndpoint + "unblacklist/" + emailDomain;
		let response = await this.deleteData(url, null, true);
		return response;
	}
}
