<template>
  <v-container fluid>
    <div class="d-flex flex-column fill-height flex-grow-1">
      <div class="d-flex justify-space-between">
        <div class="text-h4">Users</div>
        <!--  <div>
          <v-btn
            @click="openNotificationDialog(2)"
            large
            color="red"
            class="white--text body-1 mr-4"
          >
            Notify All
          </v-btn>
          <v-btn
            @click="openNotificationDialog(1)"
            large
            color="red"
            class="white--text body-1"
            :disabled="!selectedUsers.length"
          >
            Notify Selected
          </v-btn>
        </div>-->
      </div>
      <div class="flex-grow-1">
        <t-table
          :headers="headers"
          :namespace="namespace"
          :permissions-namespace="permissionsNamespace"
          :route-name="routeName"
          :show-button="showButton"
          :show-soft-deleted-switch="false"
          :soft-delete-button="false"
          :show-select="true"
          :delete-store-url="''"
          :id="idValue"
          @row-selected="userSelected"
        />
      </div>
    </div>
    <t-dialog-wrapper
      :show="showNotificationDoalog"
      width="600"
      @close-dialog="closeNotificationDialog"
    >
      <send-notification
        v-if="showNotificationDoalog"
        :send-type="notificationDialogType"
        :users="selectedUsers"
        @cancel-send-notification="closeNotificationDialog"
        @send-notification="sendNotification"
      />
    </t-dialog-wrapper>
  </v-container>
</template>

<script>
export default {
  name: "AdminUsers",
  components: {
    TTable: () => import("@/components/Core/Table/TTable"),
    TDialogWrapper: () => import("@/components/Dialog/TDialogWrapper"),
    SendNotification: () =>
      import("@/components/Admin/Users/SendNotification.vue"),
  },
  data() {
    return {
      routeName: "user",
      showButton: true,
      showSoftDeletedSwitch: true,
      namespace: "adminUsers",
      permissionsNamespace: "user",
      idValue: "userProfileId",
      softDeleteButton: true,
      deleteStoreUrl: "adminUsers/deleteItem",
      headers: [
        {
          text: "Username",
          align: "left",
          value: "username",
          sortable: false,
        },
        {
          text: "Email",
          align: "left",
          value: "email",
          sortable: false,
        },
        {
          text: "First name",
          align: "left",
          value: "firstName",
          sortable: false,
        },
        {
          text: "Last name",
          align: "left",
          value: "lastName",
          sortable: false,
        },
        {
          text: "Provider",
          align: "left",
          value: "provider",
          sortable: false,
        },
        {
          text: "Email verified",
          align: "left",
          value: "emailVerified",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      selectedUsers: [],
      showNotificationDoalog: false,
      notificationDialogType: 1, // 1: selected, 2: all
    };
  },
  methods: {
    userSelected(selectedUsers) {
      this.selectedUsers = [...selectedUsers];
    },
    openNotificationDialog(dialogType) {
      this.showNotificationDoalog = true;
      this.notificationDialogType = dialogType;
    },
    closeNotificationDialog() {
      this.showNotificationDoalog = false;
    },
    async sendNotification(notificationData) {
      if (this.notificationDialogType === 1) {
        const selectedUsersIds = this.selectedUsers.map(
          (item) => item.userProfileId
        );
        await this.$store.dispatch("adminUsers/notifySelectedUsers", {
          ...notificationData,
          recipientId: selectedUsersIds[0],
        });
      } else if (this.notificationDialogType === 2) {
        await this.$store.dispatch(
          "adminUsers/notifyAllUsers",
          notificationData
        );
      }
      this.closeNotificationDialog();
    },
  },
};
</script>

<style></style>
