import moment from "moment";

const getToken = state => state.token;
const authUser = state => state.authUser;
const getValidUntil = state => state.validUntil;
const checkTokenTime = state =>
	state.token
		? moment.unix(state.validUntil).diff(moment().valueOf(), "minutes") > 0
		: false;
const userPermissions = state => state.userPermissions;

export default {
	getToken,
	authUser,
	getValidUntil,
	checkTokenTime,
	userPermissions
};
