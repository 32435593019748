import AdminApi from "@/api/admin/reasons";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const errorHandling = new ErrorHandling();

const getItems = async ({ commit }) => {
  commit("setLoading", true);
  try {
    const { data } = await adminApi.getList();
    commit("setItemsData", data);
    commit("setLoading", false);
    return data
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error);
    throw new Error(error);
  }
};

export default {
  getItems,
};
