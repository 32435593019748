const items = (state) => state.items;
const loading = (state) => state.loading;
const pagination = (state) => state.pagination;
const totalCount = (state) => state.totalCount;

export default {
  items,
  loading,
  totalCount,
  pagination,
};
