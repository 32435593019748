<template>
  <v-container fluid>
    <div class="d-flex flex-column fill-height flex-grow-1">
      <div class="d-flex">
        <div class="text-h4">Countries</div>
        <v-btn
          large
          color="red"
          class="white--text ml-auto"
          @click="openCountryAddModal"
        >
          Add country
        </v-btn>
        <v-btn
          large
          color="red"
          class="white--text ml-auto"
          v-if="permissionToCreateItem"
        >
          Create new
        </v-btn>
        <t-dialog-wrapper
          :show="countryAddModal"
          width="600"
          @close-dialog="closeCountryAddModal"
        >
          <t-form-dialog
            v-if="countryAddModal"
            :title="'Add new country'"
            :form-data="formData"
            :initial-data="countryInfo"
            @cancel="closeCountryAddModal"
            countries
          >
          </t-form-dialog>
        </t-dialog-wrapper>
      </div>
      <div class="flex-grow-1">
        <t-table
          :headers="headers"
          :namespace="namespace"
          :permissions-namespace="permissionsNamespace"
          :route-name="routeName"
          :id="idValue"
          :show-button="true"
          :delete-store-url="deleteStoreUrl"
          :custom-action-btn="customActionBtn"
          @custom-action="openCountryInfoModal"
        />

        <t-dialog-wrapper
          :show="countryInfoModal"
          width="650"
          @close-dialog="closeCountryInfoModal"
        >
          <t-form-dialog
            v-if="countryInfoModal"
            :title="'Country info'"
            @cancel="closeCountryInfoModal"
            edit-info
            :form-data="formData"
            :initial-data="countryInfo"
            countries
          >
          </t-form-dialog>
        </t-dialog-wrapper>
      </div>
    </div>
  </v-container>
</template>

<script>
import permissionsMixin from "@/mixins/permissions";

export default {
  name: "AdminCountries",
  components: {
    TTable: () => import("@/components/Core/Table/TTable"),
    TDialogWrapper: () => import("@/components/Dialog/TDialogWrapper"),
    TFormDialog: () => import("@/components/Dialog/TFormDialog"),
  },
  mixins: [permissionsMixin],
  data() {
    return {
      routeName: "countries",
      namespace: "adminCountries",
      permissionsNamespace: "country",
      idValue: "id",
      countryInfo: null,
      deleteStoreUrl: "adminCountries/deleteItem",
      customActionBtn: {
        text: "View",
        icon: "mdi-card-search-outline",
      },
      headers: [
        {
          text: "Country name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Continent",
          align: "left",
          value: "continent",
          sortable: false,
        },
        {
          text: "Country Code (ISO 2)",
          align: "left",
          value: "countryCodeIso2",
          sortable: false,
        },
        {
          text: "Country Code (ISO 3)",
          align: "left",
          value: "countryCodeIso3",
          sortable: false,
        },
        {
          text: "Country ID",
          align: "left",
          value: "id",
          sortable: false,
        },
        {
          text: "View country",
          align: "center",
          sortable: false,
          value: "customAction",
        },
        {
          text: "Actions",
          align: "center",
          view: true,
          sortable: false,
          value: "actions",
        },
      ],
      formData: null,
      countryAddModal: false,
      countryInfoModal: false,
    };
  },
  mounted() {
    this.createFormData();
  },
  methods: {
    createFormData(item) {
      let formData = [
        {
          stepFields: {
            name: {
              type: "text",
              value: item?.name ? item.name : "",
              name: "name",
              placeholder: "Enter Country Name",
              label: "Country name",
              required: true,
            },
            countryCodeIso2: {
              type: "text",
              value: item?.countryCodeIso2 ? item.countryCodeIso2 : "",
              name: "title",
              placeholder: "Enter Country Code",
              label: "Country code",
              required: true,
              textLength: 2,
            },
            countryCodeIso3: {
              type: "text",
              value: item?.countryCodeIso3 ? item.countryCodeIso3 : "",
              name: "iso3",
              placeholder: "Enter ISO 3",
              label: "ISO 3",
              required: true,
              textLength: 3,
            },
            continent: {
              type: "select",
              value: item?.continent ? item.continent : "",
              name: "continent",
              placeholder: "Pick a continent",
              label: "Pick a continent",
              required: true,
              items: [
                "Asia",
                "Europe",
                "Africa",
                "NorthAmerica",
                "Oceania",
                "Antarctica",
                "SouthAmerica",
              ],
            },
          },
        },
      ];
      this.formData = formData;
    },
    openCountryAddModal() {
      this.createFormData(null);
      this.countryAddModal = true;
    },
    closeCountryAddModal() {
      this.countryAddModal = false;
    },
    openCountryInfoModal(item) {
      this.countryInfo = item;
      this.createFormData(item);
      this.countryInfoModal = true;
    },
    closeCountryInfoModal() {
      this.countryInfoModal = false;
    },
  },
};
</script>

<style>
.v-snack__content {
  text-align: center !important;
}
</style>
