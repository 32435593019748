<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <loader v-if="loading" />
        <t-form-wrapper
          v-else
          :form-data="formData"
          :callback-route="callbackRoute"
          :store-url="storeUrl"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    TFormWrapper: () => import("@/components/Form/TFormWrapper"),
    Loader: () => import("@/components/Core/Loader")
  },
  name: "EditSponsor",
  data() {
    return {
      formData: null,
      loading: true,
      storeUrl: "adminSponsors/updateItem",
      callbackRoute: "list-sponsor"
    };
  },
  computed: {
    ...mapGetters({
      sponsor: "adminSponsors/item"
    })
  },
  async mounted() {
    const { uid } = this.$route.params;
    await this.$store.dispatch("adminSponsors/getItem", uid);
    this.formData = this.createFormData();
    this.loading = false;
  },
  methods: {
    createFormData() {
      const { auctionSponsorId, name, title, description, logo } = this.sponsor;
      let formData = [
        {
          stepTitle: "General",
          stepFields: {
            auctionSponsorId: {
              type: "text",
              disabled: true,
              value: auctionSponsorId,
              placeholder: "Sponsor ID",
              label: "ID",
              required: true
            },
            name: {
              type: "text",
              value: name,
              name: "name",
              placeholder: "Enter Sponsor Name",
              label: "Name",
              required: true
            },
            title: {
              type: "text",
              value: title,
              name: "title",
              placeholder: "Enter Sponsor Title",
              label: "Title",
              required: true
            },
            description: {
              type: "textarea",
              value: description,
              name: "description",
              placeholder: "Enter Sponsor Description",
              label: "Description",
              required: true
            }
          }
        },
        {
          stepTitle: "Media files",
          stepFields: {
            logo: {
              type: "fileUpload",
              showPasteUrl: true,
              placeholder: "Upload file",
              label: "Upload file",
              multiple: false,
              convertToString: true,
              returnObject: false,
              allowedExtensions: ".jpg, .jpeg, .png, .webp, .gif",
              value: [logo]
            }
          }
        }
      ];
      return formData;
    }
  }
};
</script>

<style></style>
