import AdminApi from "@/api/admin/AdminApi";

export default class AdminNotificationsApi extends AdminApi {
	constructor() {
		super();
		this.baseEndpoint = `${this.domain}notifyapi/v1.0/notify/`;
	}

  async notifySelectedUsers(payload) {
    payload.language = this.getLang();
		const url = `${this.baseEndpoint}users`;
		const response = await this.insertOrUpdateData(url, payload);
		return response;
  }
  
  async notifyAllUsers(payload) {
    payload.language = this.getLang();
		const url = `${this.baseEndpoint}all`;
		const response = await this.insertOrUpdateData(url, payload);
		return response;
	}
}
