const setLoading = (state, value) => {
	state.loading = value;
};

const setFromDate = (state, value) => {
  state.fromDate = value;
};

const setToDate = (state, value) => {
  state.toDate = value;
};

export default {
	setLoading,
	setFromDate,
	setToDate,
};
