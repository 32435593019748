import AdminApi from "@/api/admin/AdminApi";

export default class AuthUserApi extends AdminApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "provisionnew/v2/user";
	}

	async getAuthUser() {
		const { data } = await this.getData(`${this.baseEndpoint}/info`);
		return data;
	}
}
