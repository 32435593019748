const show = ({ commit }, options) => {
	commit("setOptions", {
		show: true,
		...options
	});
}

const hide = ({ commit }) => {
	commit("setOptions", {
		show: false,
		text: "",
		color: "",
		timeout: 3000,
		minWidth: "360",
		action: false,
		actionText: ""
	});
}

export default {
	show,
	hide
};
