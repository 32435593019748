import Vue from "vue";
const setItemsData = (state, value) => {
  state.items = value.result;
  state.totalCount = value.pagination.totalCount;
};

const setItemData = (state, value) => {
  state.item = value;
};

const setLoading = (state, value) => {
  state.loading = value;
};

const modalOpened = (state, value) => {
  state.modalOpened = value;
};

const setPagination = (state, value) => {
  Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

export default {
  setItemsData,
  setItemData,
  modalOpened,
  setPagination,
  setLoading,
};
