import AdminApi from "@/api/admin/products";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const errorHandling = new ErrorHandling();
//all is for select menu in create/edit auction
// todo get all data from backend when sending all:true param in request
const getItems = async ({ commit, state }, all = false) => {
  commit("setLoading", true);
  const options = JSON.parse(JSON.stringify(state.pagination));
  Object.keys(options).forEach(function() {
    options["SkipCount"] = all
      ? 0
      : (options["page"] - 1) * options["itemsPerPage"];
    if (!all) {
      options["MaxResultCount"] = options["itemsPerPage"];
    }
    options["sortDesc"] = options["sortDesc"].toString();
    options["sortBy"] = options["sortBy"].toString();
  });
  try {
    const { data } = await adminApi.getList(options);
    if (all) {
      data.items.sort((a, b) =>
        a.name.trimStart().localeCompare(b.name.trimStart())
      );
    }
    commit("setItemsData", data);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error, { pagination: options });
  }
};
const setPagination = async ({ commit }, payload) => {
  commit("setPagination", payload);
};

const getItem = async ({ commit }, itemId) => {
  commit("setLoading", true);
  try {
    const { data } = await adminApi.getItem(itemId);
    commit("setItemData", data);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error, { itemId });
  }
};

const insertItem = async ({ commit }, item) => {
  commit("setLoading", true);
  try {
    await adminApi.insertItem(item);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error, { item });
  }
};

const updateItem = async ({ commit }, item) => {
  commit("setLoading", true);
  try {
    await adminApi.updateItem(item);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error, { item });
  }
};

const deleteItem = async ({ commit }, item) => {
  try {
    commit("setLoading", true);
    await adminApi.deleteItem(item);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error, { item });
    throw error;
  }
};

export default {
  getItems,
  getItem,
  setPagination,
  insertItem,
  updateItem,
  deleteItem,
};
