import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  items: [],
  item: {},
  loading: false,
  totalCount: 0,
  pagination: {
    page: 1,
    itemsPerPage: 10,
    sortBy: ["roleName"],
    sortDesc: [true],
    desc: true,
    mustSort: true,
    Name: "",
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
