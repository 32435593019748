export default {
    // Set cookie values
   setCookie(cname, cvalue, exdays=1) {
    let date = new Date();
    let domain=''
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
  let expires = "expires="+date.toUTCString();
      if(location.hostname == "tatatu.com"){
      domain = "domain=.tatatu.com;";
    }
   let sameSite = "SameSite=Lax";
   let path = "path=/";
    if(!this.checkCookie(cname))
    document.cookie = cname + "=" + cvalue + ";" + expires+";" + path+ ";" +sameSite+ ";" + domain;
  //   document.cookie = cname + "=" + cvalue;
  },
  // Set cookie by name
   getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  },
  // Check if cookie exists
   checkCookie(cname) {
    let name = this.getCookie(cname);
    if (name != "") {
      return true
    }
    return false
  },
  // Clear cookie
   clearCookie(cname) {
     document.cookie = cname +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

};
