import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
	summary: {},
  loading: false,
  loadingSummary: false,
  deletionsInExecution: [],
  // totalCount: 0,
  pagination: {
    page: 1,
    itemsPerPage: 10,
  //   Name: "",
  //   reportReason: "",
  //   fromDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleString(
  //     "fr-CA",
  //     {
  //       year: "numeric",
  //       month: "numeric",
  //       day: "numeric",
  //     }
  //   ),
  //   toDate: "",
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
