<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <t-form-wrapper
          v-if="auctionFormData"
          :form-data="auctionFormData"
          :callback-route="callbackRoute"
          :store-url="storeUrl"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    TFormWrapper: () => import("@/components/Form/TFormWrapper"),
  },
  name: "CreateEditAuction",
  data() {
    return {
      auctionFormData: null,
      storeUrl: "adminProducts/insertItem",
      callbackRoute: "list-product",
    };
  },
  mounted() {
    this.auctionFormData = this.createFormData();
  },
  methods: {
    createFormData() {
      let formData = [
        {
          stepTitle: "General",
          stepFields: {
            name: {
              type: "text",
              value: "",
              placeholder: "Enter Product Name",
              label: "Name",
              required: true,
            },
            description: {
              type: "textarea",
              value: "",
              placeholder: "Enter Product Description",
              label: "Description",
              required: true,
            },
            costValue: {
              type: "number",
              value: "", //number
              placeholder: "Enter product value",
              label: "Value",
              required: true,
            },
          },
        },
        {
          stepTitle: "Media files",
          stepFields: {
            auctionMedia: {
              type: "fileUpload",
              showPasteUrl: true,
              placeholder: "Upload file",
              label: "Upload file",
              multiple: true,
              value: [
                {
                  identifier: "",
                  mediaType: "",
                  mediaUrl: "",
                },
              ],
            },
          },
        },
        {
          stepTitle: "Bid increment",
          stepFields: {
            bidIncrement: {
              type: "bidIncrement",
              value: [
                {
                  bidFrom: 0, //number
                  bidTo: 0, //number
                  step: 0, //number
                },
              ],
            },
          },
        },
      ];
      return formData;
    },
  },
};
</script>

<style></style>
