<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <div v-if="amount !== ''" class="text-h5">{{ amount }}</div>
      <div v-else>
        <v-progress-circular indeterminate color="red" />
      </div>
      <div class="text-subtitle-1 mt-2">
        {{ text }}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    amount: {
      type: [Number, String],
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>
