import AdminApi from "@/api/admin/AdminApi";

export default class AdminAuctionsApi extends AdminApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "auctadmwsvc/Auction/";
		this.auctionWebEndpoint = this.domain + "auctwebsvc/Auction/";
		this.walletEndpoint = this.domain + "triboonew/api/v1.0/wallet/";
	}

	async getList(pagination) {
		let url = this.baseEndpoint + "Get";
		let response = await this.getData(url, pagination);
		return response;
	}

	async getItem(id) {
		let url = this.baseEndpoint + "Get/" + id;
		let response = await this.getData(url);
		return response;
	}

	async insertItem(item) {
		let url = this.baseEndpoint + "Insert/";
		let response = await this.insertOrUpdateData(url, item);
		return response;
	}

	async updateItem(item) {
		let url = this.baseEndpoint + "Update/";
		let response = await this.insertOrUpdateData(url, item);
		return response;
	}

	async deleteItem(item) {
		let url = this.baseEndpoint + "Delete/";
		let response = await this.deleteData(url, item);
		return response;
	}

	async getUser(id) {
		let url = this.walletEndpoint + id + "/user/";
		let response = await this.getData(url);
		return response;
	}

	async getLiveAuctions() {
		let url = this.auctionWebEndpoint + "Get/?status=In%20Progress";
		let response = await this.getData(url);
		return response;
	}
}
