<template>
  <v-container fluid>
    <div class="d-flex flex-column fill-height flex-grow-1">
      <div class="d-flex">
        <div class="text-h4">Blocked Domains</div>
        <v-btn
          :to="{ name: 'create-' + routeName }"
          large
          color="red"
          class="white--text ml-auto"
          v-if="permissionToBlockDomain"
        >
          Block a domain
        </v-btn>
      </div>
      <div class="flex-grow-1">
        <t-table
          hide-search
          :headers="tableHeaders"
          namespace="adminBlockedDomains"
          :permissions-namespace="permissionsNamespace"
          :route-name="routeName"
          :custom-action-btn="customActionBtn"
          @custom-action="showUnblockDialog"
          :id="idValue"
        />
      </div>
    </div>
    <t-dialog-wrapper :show="unblockDialog" @close-dialog="closeUnblockDialog">
      <t-basic-dialog
        btn-text="Unblock"
        :title="selectedDomain || ''"
        @confirm="unblockDomain"
      >
        <div class="text-body-2 grey--text">
          Do you really want to unblock the domain?
        </div>
      </t-basic-dialog>
    </t-dialog-wrapper>
  </v-container>
</template>

<script>
import permissionsMixin from "@/mixins/permissions";

export default {
  name: "AdminBlockedDomains",
  components: {
    TTable: () => import("@/components/Core/Table/TTable"),
    TDialogWrapper: () => import("@/components/Dialog/TDialogWrapper"),
    TBasicDialog: () => import("@/components/Dialog/TBasicDialog"),
  },
  mixins: [permissionsMixin],
  data() {
    return {
      unblockDialog: false,
      selectedDomain: null,
      routeName: "blocked-domain",
      permissionsNamespace: "domain",
      idValue: "name",
      deleteStoreUrl: "adminBlockedDomains/deleteItem",
      customActionBtn: {
        text: "Unblock",
        icon: "mdi-lock-open-variant-outline",
      },
      headers: [
        {
          text: "Domain",
          align: "left",
          value: "domain",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    tableHeaders() {
      if (this.permissionToUnblockDomain) {
        return [
          ...this.headers,
          {
            text: "Unblock",
            align: "center",
            sortable: false,
            value: "customAction",
          },
        ];
      }
      return this.headers;
    },
  },
  methods: {
    showUnblockDialog(item) {
      this.unblockDialog = true;
      this.selectedDomain = item.domain;
    },
    closeUnblockDialog() {
      this.unblockDialog = false;
      this.selectedDomain = null;
    },
    async unblockDomain() {
      const response = await this.$store.dispatch(
        "adminBlockedDomains/unblockDomain",
        this.selectedDomain
      );
      if (response.status === 200) {
        this.$store.dispatch("adminBlockedDomains/getItems");
        this.$store.dispatch("snackbar/show", {
          text: "Domain '" + this.selectedDomain + "' has been unblocked",
        });
      }
      this.closeUnblockDialog();
    },
  },
};
</script>

<style></style>
