import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import Vuelidate from "vuelidate";

import Helpers from "@/plugins/Helpers";

Vue.use(new Helpers());
Vue.use(Vuelidate);
Vue.use(require("vue-moment"));

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
