import moment from "moment";

const summary = (state) => state.summary;
const loading = (state) => state.loading;
const loadingSummary = (state) => state.loadingSummary;
const pagination = (state) => state.pagination;

const items = (state) => {
	state.items.forEach((item) => {
		if (item.startDate) {
			item.startDate = moment(item.startDate).format("YYYY-MM-DD HH:MM:ss");
		}
		if (item.endDate) {
			item.endDate = moment(item.endDate).format("YYYY-MM-DD HH:MM:ss");
		}
	});
	return state.items;
};

export default {
	summary,
	items,
	loading,
	loadingSummary,
	pagination,
};
