import AdminApi from "@/api/admin/blockedDomains";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const errorHandling = new ErrorHandling();

const getItems = async ({ commit, state }) => {
  commit("setLoading", true);
  const options = JSON.parse(JSON.stringify(state.pagination));
  Object.keys(options).forEach(function() {
    options["pageNumber"] = options["page"];
    options["pageSize"] = options["itemsPerPage"];
  });
  try {
    const { data } = await adminApi.getList(options);
    commit("setItemsData", data);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error, { pagination: options });
  }
};

const setPagination = async ({ commit }, payload) => {
  commit("setPagination", payload);
};

const insertItem = async ({ commit }, item) => {
  commit("setLoading", true);
  try {
    await adminApi.insertItem(item);

    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);

    errorHandling.onError(error, { item });
    throw error.response.data;
  }
};

const unblockDomain = async ({ commit }, item) => {
  commit("setLoading", true);
  try {
    const response = await adminApi.unblockDomain(item);
    commit("setLoading", false);
    return response;
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error, { item });
    throw error;
  }
};

export default {
  getItems,
  setPagination,
  insertItem,
  unblockDomain,
};
