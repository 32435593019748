const items = (state) => state.items;
const item = (state) => state.item;
const loading = (state) => state.loading.user;
const pagination = (state) => state.pagination;
const userActivity = (state) => state.userActivity;
const userActivityTotalCount = (state) => state.userActivityTotalCount;
const userActivityPagination = (state) => state.userActivityPagination;
const userActivityLoading = (state) => state.loading.userActivity;
const userRolesLoading = (state) => state.loading.userRoles;
const userRoleGroupsLoading = (state) => state.loading.userRoleGroups;
const allInterests = (state) => state.allInterests;
const allInterestsLoading = (state) => state.loading.allInterests;
const allInterestsError = (state) => state.allInterestsError;
const userInterests = (state) => state.userInterests;
const userInterestsLoading = (state) => state.loading.userInterests;
const userInterestsError = (state) => state.error.userInterests;
const resultLength = (state) => state.resultLength;

export default {
  items,
  item,
  loading,
  pagination,
  userActivity,
  userActivityTotalCount,
  userActivityPagination,
  userActivityLoading,
  userRolesLoading,
  userRoleGroupsLoading,
  allInterests,
  allInterestsLoading,
  allInterestsError,
  userInterests,
  userInterestsLoading,
  userInterestsError,
  resultLength
};
