import AdminApi from "@/api/admin/AdminApi";

export default class AdminDonationsApi extends AdminApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain;
  }

  async getUserDonation(userProfileId) {
    let url =
      this.baseEndpoint +
      `identityadm/v1.0/admin/users/${userProfileId}/donations`;

    let response = await this.getData(url);
    return response;
  }

  async updateUserDonation(userProfileId, payload) {
    let url =
      this.baseEndpoint +
      `identityadm/v1.0/admin/users/${userProfileId}/donations`;

    let response = await this.putData(url, payload);
    return response;
  }
}
