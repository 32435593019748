import AdminRoles from "@/components/Admin/Roles/List";
import CreateRole from "@/components/Admin/Roles/Create";
import EditRole from "@/components/Admin/Roles/Edit";

export default [
	{
		path: "roles",
		name: "list-role",
		component: AdminRoles
	},
	{
		path: "create-role",
		name: "create-role",
		component: CreateRole
	},
	{
		path: "edit-role/:uid",
		name: "edit-role",
		props: true,
		component: EditRole
	}
];
