import Vue from "vue";
const setItemsData = (state, value) => {
  state.items = value.result ? value.result : state.items;
};

const setLoading = (state, value) => {
  state.loading = value;
};

const setPagination = (state, value) => {
  Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

const setPrevNextPage = (state, value) => {
  state.pagination.hasPrevious = value.hasPrevious;
  state.pagination.hasNext = value.hasNext;
};

const setFromDate = (state, value) => {
  state.pagination.fromDate = value;
};

const setToDate = (state, value) => {
  state.pagination.toDate = value;
};

const setModerationType = (state, value) => {
  state.pagination.moderationResult = value;
  state.pagination.page = 1;
};
const setResultLength = (state, value) => {
  state.resultLength = value;
};


export default {
  setItemsData,
  setPagination,
  setPrevNextPage,
  setLoading,
  setFromDate,
  setToDate,
  setModerationType,
  setResultLength
};
