import AdminApi from "@/api/admin/AdminApi";

export default class AdminPostsApi extends AdminApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "identityadm/v1.0/post-moderation/";
  }

  async getList(pagination) {
    let url = this.baseEndpoint;
    let response = await this.getData(url, pagination);
    return response;
  }

  async approvePost(postID) {
    let url = this.baseEndpoint + `approve-post?postId=${postID}`;
    let response = await this.patchData(url);
    return response;
  }

  async rejectPost(postID) {
    let url = this.baseEndpoint + `reject-post?postId=${postID}`;
    let response = await this.patchData(url);
    return response;
  }
}
