import AdminApi from "@/api/admin/postModeration";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const errorHandling = new ErrorHandling();

const getItems = async ({ commit, state }) => {
  commit("setLoading", true);
  const options = JSON.parse(JSON.stringify(state.pagination));
  Object.keys(options).forEach(function() {
    options["pageNumber"] = options["page"];
    options["pageSize"] = options["itemsPerPage"];
    options["searchTerm"] = options["Name"];
  });
  try {
    const { data } = await adminApi.getList(options);
    commit("setItemsData", data);
    commit("setPrevNextPage", {
      hasPrevious: data.pagination.hasPrevious,
      hasNext: data.pagination.hasNext,
    });
    commit("setResultLength", data.result.length);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error, { pagination: options });
    throw new Error(error);
  }
};

const setPagination = async ({ commit }, payload) => {
  commit("setPagination", payload);
};

const approvePost = async ({ commit, dispatch }, postID) => {
  commit("setLoading", true);
  try {
    await adminApi.approvePost(postID);
    await dispatch("getItems");
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error);
    throw new Error(error);
  }
};

const rejectPost = async ({ commit, dispatch }, postID) => {
  commit("setLoading", true);
  try {
    await adminApi.rejectPost(postID);
    await dispatch("getItems");
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error);
    throw new Error(error);
  }
};

export default {
  getItems,
  setPagination,
  approvePost,
  rejectPost,
};
