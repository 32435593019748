import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  items: [],
  item: {},
  pagination: {
    page: 1,
    itemsPerPage: 10,
    clientId: process.env.VUE_APP_CLIENT_ID,
    sortBy: ["providerUserId"],
    sortDesc: [true],
    showOnlySoftDeleted: false,
    desc: true,
    mustSort: true,
    Name: "",
  },
  resultLength: 10,
  allInterests: [],
  userInterests: [],
  userActivity: [],
  userActivityTotalCount: 0,
  userActivityPagination: {
    page: 1,
    itemsPerPage: 10,
    clientid: process.env.VUE_APP_CLIENT_ID,
    userid: "",
  },
  loading: {
    user: false,
    userActivity: false,
    allInterests: false,
    userRoles: false,
    userInterests: false,
    userRoleGroups: false,
  },
  error: {
    user: false,
    allInterests: false,
    userInterests: false,
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
