import AdminApi from "@/api/admin/AdminApi";

export default class AdminReasonsApi extends AdminApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "postsvc/v1.0/report/reasons";
  }

  async getList() {
    let url = this.baseEndpoint;
    let response = await this.getData(url);
    return response;
  }
}
