<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <loader v-if="loading" />
        <t-form-wrapper
          v-else
          :form-data="formData"
          :callback-route="callbackRoute"
          :store-url="storeUrl"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    TFormWrapper: () => import("@/components/Form/TFormWrapper"),
    Loader: () => import("@/components/Core/Loader")
  },
  name: "EditRoleGroup",
  data() {
    return {
      formData: null,
      loading: true,
      storeUrl: "adminRoleGroups/updateItem",
      callbackRoute: "list-role-group"
    };
  },
  computed: {
    ...mapGetters({
      roleGroup: "adminRoleGroups/item",
      roles: "adminRoles/items"
    })
  },
  async mounted() {
    const { uid } = this.$route.params;
    await this.$store.dispatch("adminRoleGroups/getItem", uid);
    await this.$store.dispatch("adminRoles/getAllItems");
    this.formData = this.createFormData();
    this.loading = false;
  },
  methods: {
    createFormData() {
      const { id, name, displayName, description, roles } = this.roleGroup;
      let formData = [
        {
          stepTitle: "General",
          stepFields: {
            id: {
              type: "text",
              disabled: true,
              value: id,
              placeholder: "Role Group ID",
              label: "ID",
              required: true
            },
            name: {
              type: "text",
              value: name,
              name: "name",
              placeholder: "Enter Role Group Name",
              label: "Role Group Name",
              required: true
            },
            displayName: {
              type: "text",
              value: displayName,
              name: "displayName",
              placeholder: "Enter Role Group Display Name",
              label: "Role Group Display Name",
              required: true
            },
            description: {
              type: "textarea",
              value: description,
              name: "description",
              placeholder: "Enter Role Group Description",
              label: "Role Group Description",
              required: true
            },
            roleIds: {
              type: "select",
              value: roles,
              items: this.roles,
              itemValue: "id",
              itemText: "roleDisplayName",
              name: "roleIds",
              placeholder: "Select roles",
              label: "Roles",
              required: true,
              returnObject: true,
              multiple: true
            }
          }
        }
      ];
      return formData;
    }
  }
};
</script>

<style></style>
