import AdminApi from "@/api/admin/users";
import AdminNotificationApi from "@/api/admin/notifications";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const adminNotificationApi = new AdminNotificationApi();
const errorHandling = new ErrorHandling();

const getItems = async ({ commit, state }) => {
  commit("setLoading", { name: "user", value: true });
  const { pagination } = state;
  const options = {
    pageNumber: pagination.page,
    pageSize: pagination.itemsPerPage,
    clientId: pagination.clientId,
    text: pagination.Name,
    showOnlySoftDeleted: pagination.showOnlySoftDeleted,
    hasPrevious: pagination.hasPrevious,
    hasNext: pagination.hasNext,
  };
  try {
    const { data } = await adminApi.getList(options);
    const { result } = data;
    commit("setItemsData", result);
    commit("setPrevNextPage", {
      hasPrevious: data.pagination.hasPrevious,
      hasNext: data.pagination.hasNext,
    });
    commit("setResultLength", result.length);
  } catch (error) {
    errorHandling.onError(error, { pagination: options });
  }
  commit("setLoading", { name: "user", value: false });
};

const getLatestSignups = async ({ state }) => {
  const { clientId } = state.pagination;
  try {
    const {
      data: {
        result: { usersActivities, totalCount },
      },
    } = await adminApi.getLatestSignups(clientId);
    return { usersActivities, totalCount };
  } catch (error) {
    errorHandling.onError(error, { clientId });
  }
};

const getLatestLogins = async ({ state }) => {
  const { clientId } = state.pagination;
  try {
    const {
      data: {
        result: { usersActivities, totalCount },
      },
    } = await adminApi.getLatestLogins(clientId);
    return { usersActivities, totalCount };
  } catch (error) {
    errorHandling.onError(error, { clientId });
  }
};

const setPagination = async ({ commit }, payload) => {
  commit("setPagination", payload);
};

const getItem = async ({ commit, dispatch, rootState }, itemId) => {
  commit("setLoading", { name: "user", value: true });
  try {
    const getblockedUsersPayload = {
      SkipCount: "",
      MaxResultCount: "",
      BlockedBy: "",
      Name: "",
      StartDateFrom: "",
      StartDateTo: "",
    };

    await dispatch("auctionBlockedUsers/getItems", getblockedUsersPayload, {
      root: true,
    });

    const {
      data: { result },
    } = await adminApi.getItem(itemId);

    const blocked = rootState.auctionBlockedUsers.items.some((item) => {
      return item.userId === result.tatatuDbUserId;
    });

    const mappedRes = { blockedForAuction: blocked, ...result };

    commit("setItemData", mappedRes);
  } catch (error) {
    errorHandling.onError(error, { itemId });
  }
  commit("setLoading", { name: "user", value: false });
};

const setUserState = async ({ commit, dispatch }, { userId, block }) => {
  commit("setLoading", { name: "user", value: true });
  try {
    if (block) {
      await adminApi.blockUser(userId);
    } else {
      await adminApi.activateUser(userId);
    }
    await dispatch("getItem", userId);
  } catch (error) {
    errorHandling.onError(error, { userId });
  }
  commit("setLoading", { name: "user", value: false });
};

const updateItem = async ({ commit, dispatch }, item) => {
  commit("setLoading", { name: "user", value: true });
  try {
    const { providerUserId } = item;
    await adminApi.updateItem(item);
    await dispatch("getItem", providerUserId);
  } catch (error) {
    errorHandling.onError(error, { item });
  }
  commit("setLoading", { name: "user", value: false });
};

const deleteItem = async ({ commit }, payload) => {
  commit("setLoading", { name: "user", value: true });
  try {
    await adminApi.deleteItem(payload.id);
  } catch (error) {
    errorHandling.onError(error, { payload });
    throw error;
  }
  commit("setLoading", { name: "user", value: false });
};

const getUserActivity = async ({ commit, state }) => {
  commit("setLoading", { name: "userActivity", value: true });
  const { userActivityPagination } = state;
  const options = {
    pageNumber: userActivityPagination.page,
    pageSize: userActivityPagination.itemsPerPage,
    userProfileId: userActivityPagination.userid,
  };
  try {
    const {
      data: {
        result,
        pagination: { totalCount },
      },
    } = await adminApi.getUserActivity(options);
    commit("setUserActivity", result);
    commit("setUserActivityTotalCount", totalCount);
  } catch (error) {
    errorHandling.onError(error, { pagination: options });
  }
  commit("setLoading", { name: "userActivity", value: false });
};

const setUserActivityPagination = async ({ commit }, payload) => {
  commit("setUserActivityPagination", payload);
};

const getAccountTypes = async () => {
  const {
    data: { result },
  } = await adminApi.getAccountTypes();
  return result.map((item) => ({
    text: item.name,
    value: `${item.name[0].toLowerCase()}${item.name.slice(1)}`,
    id: item.id,
  }));
};

const changeUserAccountType = async (context, { userId, accountType }) => {
  try {
    await adminApi.setAccountType(userId, accountType);
  } catch (error) {
    errorHandling.onError(error, { userId, accountType });
  }
};

const addRole = async ({ commit, dispatch }, { userId, roleId }) => {
  try {
    commit("setLoading", { name: "userRoles", value: true });
    await adminApi.addRoleToUser(userId, roleId);
    await dispatch("getItem", userId);
  } catch (error) {
    errorHandling.onError(error, { userId, roleId });
  } finally {
    commit("setLoading", { name: "userRoles", value: false });
  }
};

const removeRole = async ({ commit, dispatch }, { userId, roleId }) => {
  try {
    commit("setLoading", { name: "userRoles", value: true });
    await adminApi.removeRoleFromUser(userId, roleId);
    await dispatch("getItem", userId);
  } catch (error) {
    errorHandling.onError(error, { userId, roleId });
  } finally {
    commit("setLoading", { name: "userRoles", value: false });
  }
};

const addRoleGroup = async ({ commit, dispatch }, { userId, roleGroupId }) => {
  try {
    commit("setLoading", { name: "userRoleGroups", value: true });
    await adminApi.addRoleGroupToUser(userId, roleGroupId);
    await dispatch("getItem", userId);
  } catch (error) {
    errorHandling.onError(error, { userId, roleGroupId });
  } finally {
    commit("setLoading", { name: "userRoleGroups", value: false });
  }
};

const removeRoleGroup = async (
  { commit, dispatch },
  { userId, roleGroupId }
) => {
  try {
    commit("setLoading", { name: "userRoleGroups", value: true });
    await adminApi.removeRoleGroupFromUser(userId, roleGroupId);
    await dispatch("getItem", userId);
  } catch (error) {
    errorHandling.onError(error, { userId, roleGroupId });
  } finally {
    commit("setLoading", { name: "userRoleGroups", value: false });
  }
};

const getAllInterests = async ({ commit }) => {
  try {
    commit("setLoading", { name: "allInterests", value: true });
    const {
      data: { result },
    } = await adminApi.getAllInterests();
    commit("setAllInterests", result);
  } catch (error) {
    errorHandling.onError(error);
    commit("setError", { name: "allInterests", value: true });
  } finally {
    commit("setLoading", { name: "allInterests", value: false });
  }
};

const getUserInterests = async ({ commit }, payload) => {
  try {
    commit("setLoading", { name: "userInterests", value: true });
    const {
      data: { result },
    } = await adminApi.getUserInterests(payload);
    commit("setUserInterests", result);
  } catch (error) {
    errorHandling.onError(error, payload);
    commit("setError", { name: "userInterests", value: true });
  } finally {
    commit("setLoading", { name: "userInterests", value: false });
  }
};

const setUserInterests = async ({ commit }, payload) => {
  try {
    commit("setLoading", { name: "userInterests", value: true });
    await adminApi.setUserInterests(payload);
  } catch (error) {
    errorHandling.onError(error, { payload });
    commit("setError", { name: "userInterests", value: true });
  } finally {
    commit("setLoading", { name: "userInterests", value: false });
  }
};

const notifySelectedUsers = async (context, payload) => {
  try {
    await adminNotificationApi.notifySelectedUsers(payload);
  } catch (error) {
    errorHandling.onError();
  }
};

const notifyAllUsers = async (context, payload) => {
  try {
    await adminNotificationApi.notifyAllUsers(payload);
  } catch (error) {
    errorHandling.onError();
  }
};

const deleteUser = async ({ commit }, uid) => {
  commit("setLoading", { name: "user", value: true });
  try {
    await adminApi.deleteUser(uid);
  } catch (error) {
    errorHandling.onError(error);
    throw error;
  }
  commit("setLoading", { name: "user", value: false });
};

export default {
  getItems,
  getItem,
  setPagination,
  setUserState,
  updateItem,
  deleteItem,
  getLatestSignups,
  getLatestLogins,
  getUserActivity,
  setUserActivityPagination,
  getAccountTypes,
  changeUserAccountType,
  addRole,
  removeRole,
  addRoleGroup,
  removeRoleGroup,
  getAllInterests,
  getUserInterests,
  setUserInterests,
  notifySelectedUsers,
  notifyAllUsers,
  deleteUser,
};
