<template>
  <v-container fluid>
    <div class="d-flex flex-column fill-height flex-grow-1">
      <div class="d-flex">
        <div class="text-h4">Partners</div>
        <v-btn
          large
          color="red"
          class="white--text ml-auto"
          @click="openPartnerAddModal"
        >
          Add partner
        </v-btn>
        <t-dialog-wrapper
          :show="partnerAddModal"
          width="600"
          @close-dialog="closePartnerAddModal"
        >
          <t-form-dialog
            v-if="partnerAddModal"
            :title="'Add new partner'"
            :form-data="formData"
            :initial-data="partnerInfo"
            @cancel="closePartnerAddModal"
          >
          </t-form-dialog>
        </t-dialog-wrapper>
      </div>
      <div class="flex-grow-1">
        <t-table
          hide-search
          :headers="headers"
          :namespace="namespace"
          :permissions-namespace="permissionsNamespace"
          :route-name="routeName"
          :id="idValue"
          :show-button="true"
          :delete-store-url="deleteStoreUrl"
          :custom-action-btn="customActionBtn"
          @custom-action="openPartnerInfoModal"
        />
        <t-dialog-wrapper
          :show="partnerInfoModal"
          width="650"
          @close-dialog="closePartnerInfoModal"
        >
          <t-form-dialog
            v-if="partnerInfoModal"
            :title="'Partner info'"
            @cancel="closePartnerInfoModal"
            edit-info
            :form-data="formData"
            :initial-data="partnerInfo"
          >
          </t-form-dialog>
        </t-dialog-wrapper>
      </div>
    </div>
  </v-container>
</template>

<script>
import permissionsMixin from "@/mixins/permissions";

export default {
  name: "AdminPartners",
  components: {
    TTable: () => import("@/components/Core/Table/TTable"),
    TDialogWrapper: () => import("@/components/Dialog/TDialogWrapper"),
    TFormDialog: () => import("@/components/Dialog/TFormDialog"),
  },
  mixins: [permissionsMixin],
  data() {
    return {
      routeName: "partner",
      namespace: "adminPartners",
      permissionsNamespace: "partner",
      idValue: "id",
      partnerInfo: null,
      customActionBtn: {
        text: "View",
        icon: "mdi-card-search-outline",
      },
      deleteStoreUrl: "adminPartners/deleteItem",
      headers: [
        {
          text: "Partner ID",
          align: "left",
          value: "partnerId",
          sortable: false,
        },
        {
          text: "Partner name",
          align: "left",
          value: "name",
          sortable: false,
        },
        {
          text: "Description",
          align: "left",
          value: "description",
          sortable: false,
        },
        {
          text: "Logo",
          align: "left",
          value: "logo",
          sortable: false,
        },
        {
          text: "Signup Bonus",
          align: "left",
          value: "signupBonus",
          sortable: false,
        },
        {
          text: "View partner",
          align: "center",
          sortable: false,
          value: "customAction",
        },
        {
          text: "Actions",
          align: "center",
          view: true,
          sortable: false,
          value: "actions",
        },
      ],
      formData: null,
      partnerAddModal: false,
      partnerInfoModal: false,
    };
  },
  mounted() {
    this.createFormData();
  },
  methods: {
    createFormData(item) {
      let formData = [
        {
          stepTitle: "General",
          stepFields: {
            name: {
              type: "text",
              value: item?.name ? item.name : "",
              name: "name",
              placeholder: "Enter Partner Name",
              label: "Partner Name",
              required: true,
            },
            description: {
              type: "text",
              value: item?.description ? item.description : "",
              name: "description",
              placeholder: "Enter Description",
              label: "Partner Description",
              required: false,
            },
            logo: {
              type: "fileUpload",
              value: item?.logo ? item.logo : "",
              showPasteUrl: true,
              placeholder: "Upload file",
              label: "Upload file",
              multiple: false,
            },
            signupBonus: {
              type: "number",
              value: item?.signupBonus ? item.signupBonus : 0,
              name: "signupBonus",
              placeholder: "Signup bonus",
              label: "Signup bonus",
              required: false,
            },
          },
        },
      ];
      this.formData = formData;
    },
    openPartnerAddModal() {
      this.createFormData(null);
      this.partnerAddModal = true;
    },
    closePartnerAddModal() {
      this.partnerAddModal = false;
    },
    openPartnerInfoModal(item) {
      this.partnerInfo = item;
      this.createFormData(item);
      this.partnerInfoModal = true;
    },
    closePartnerInfoModal() {
      this.partnerInfoModal = false;
    },
  },
};
</script>

<style></style>
