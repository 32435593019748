<template>
  <div class="reports-wrapper d-flex flex-column" :style="{ width: '100%' }">
    <v-tabs v-model="tab" centered>
      <v-tab
        v-for="(tab, index) in tabs"
        :key="index"
        :href="`#tab-${tab.pos}`"
      >
        {{ tab.title }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="(tab, index) in tabs"
        :key="index"
        :value="`tab-${tab.pos}`"
      >
        <v-card flat>
          <component
            :is="tab.component"
            :reasons="filterReasonOptions"
          ></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Reports",
  components: {
    ReportedUsersList: () =>
      import("@/components/Admin/Reports/ReportedUserModerationList"),
    ReportedPostsList: () =>
      import("@/components/Admin/Reports/ReportedPostModerationList"),
    ReportedCommentList: () =>
      import("@/components/Admin/Reports/ReportedCommentModerationList"),
  },
  data() {
    return {
      tab: null,
      tabs: [
        {
          pos: 1,
          title: "Report Users",
          component: "ReportedUsersList",
        },
        {
          pos: 2,
          title: "Report Posts",
          component: "ReportedPostsList",
        },
        {
          pos: 3,
          title: "Report Comments",
          component: "ReportedCommentList",
        },
      ],
    };
  },
  async mounted() {
    await this.$store.dispatch("reasons/getItems");
  },
  computed: {
    ...mapGetters({
      reasons: "reasons/items",
    }),
    filterReasonOptions() {
      return [{ text: "All", value: "" }, ...this.reasonOptions];
    },
    reasonOptions() {
      return this.reasons.map(function(reason) {
        return { text: reason.reportReason, value: reason.reasonCode };
      });
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.v-window.v-tabs-items {
  overflow: visible;
}
</style>
