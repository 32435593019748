const items = (state) => {
  state.items.forEach((item) => {
    switch (item.moderationResultType) {
      case 0:
        item.moderationResultType = "OK";
        break;
      case 1:
        item.moderationResultType = "Offensive";
        break;
      case 2:
        item.moderationResultType = "Need to review";
        break;
      default:
        item.moderationResultType = "OK";
    }
    if (item.mentions) {
      item.mentions = item.mentions.map(({ userName }) => userName).toString();
    }
    if (item.location) {
      item.location = item.location.title;
    }
  });
  return state.items;
};
const loading = (state) => state.loading;
const pagination = (state) => state.pagination;
const resultLength = (state) => state.resultLength;

export default {
  items,
  loading,
  pagination,
  resultLength
};
