<template>
  <v-container fluid>
    <div class="d-flex flex-column fill-height flex-grow-1">
      <div class="d-flex">
        <div class="text-h4">Roles</div>
        <v-btn
          :to="{ name: 'create-' + routeName }"
          large
          color="red"
          class="white--text ml-auto"
          v-if="permissionToCreateItem"
        >
          Create new
        </v-btn>
      </div>
      <div class="flex-grow-1">
        <t-table
          :headers="headers"
          :namespace="namespace"
          :permissions-namespace="permissionsNamespace"
          :route-name="routeName"
          :id="idValue"
          :delete-store-url="deleteStoreUrl"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import permissionsMixin from "@/mixins/permissions";

export default {
  name: "AdminRoles",
  components: {
    TTable: () => import("@/components/Core/Table/TTable"),
  },
  mixins: [permissionsMixin],
  data() {
    return {
      routeName: "role",
      namespace: "adminRoles",
      permissionsNamespace: "role",
      idValue: "id",
      deleteStoreUrl: "adminRoles/deleteItem",
      headers: [
        {
          text: "Role ID",
          align: "left",
          value: "id",
          sortable: true,
        },
        {
          text: "Role name",
          align: "left",
          value: "roleName",
          sortable: true,
        },
        {
          text: "Role display name",
          align: "left",
          value: "roleDisplayName",
          sortable: true,
        },
        {
          text: "Description",
          align: "left",
          value: "roleDescription",
          sortable: true,
        },
        {
          text: "Actions",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
};
</script>

<style></style>
