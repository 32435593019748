import AdminApi from "@/api/admin/gdpr";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const errorHandling = new ErrorHandling();

const getSummary = async ({ commit }) => {
  commit("setLoadingSummary", true);
  try {
    const { data } = await adminApi.getSummary();
    commit("setSummaryData", data);
    commit("setLoadingSummary", false);
  } catch (error) {
    commit("setLoadingSummary", false);
    errorHandling.onError(error);
    throw new Error(error);
  }
};

const getItems = async ({ commit }) => {
  commit("setLoading", true);
  try {
    const { data } = await adminApi.getDeletionsInExecution();
    commit("setItems", data);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error);
    throw new Error(error);
  }
};

const setPagination = async ({ commit }, payload) => {
  commit("setPagination", payload);
};

const retriggerExecution = async ({ commit, dispatch }, userDeleteId) => {
  commit("setLoading", true);
  try {
    const response = await adminApi.retriggerExecution(userDeleteId);
    await dispatch("getItems");
    commit("setLoading", false);
    return response;
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error);
    throw new Error(error);
  }
};

export default {
  getSummary,
  getItems,
  setPagination,
  retriggerExecution,
};
