import Vue from "vue";
import AuthUser from "@/api/auth";
import ErrorHandling from "@/plugins/ErrorHandling";

const authUser = new AuthUser();
const errorHandling = new ErrorHandling();

const loadLocalToken = ({ commit, dispatch }) => {
	const token = Vue.helpers.getCookie("loginToken");
	const validUntil = Vue.helpers.getCookie("validUntil");
	if (token && validUntil) {
		commit("setToken", { token, validUntil });
		dispatch("checkToken");
	} else {
		window.location.href = `${process.env.VUE_APP_SSO}?callback=${process.env.VUE_APP_DOMAIN}`;
	}
};

const checkToken = async ({ getters, dispatch }) => {
	if (!getters.checkTokenTime) {
		await dispatch("clearToken");
	}
};

const setToken = ({ commit }, data) => {
	const { token, valid } = data;
	commit("setToken", {
		token,
		validUntil: valid
	});
	Vue.helpers.setCookie("loginToken", token, 30);
	Vue.helpers.setCookie("validUntil", valid, 30);
};

const clearToken = ({ commit }) => {
	commit("clearToken");
	Vue.helpers.clearCookie("loginToken");
	Vue.helpers.clearCookie("validUntil");
	window.location.href = `${process.env.VUE_APP_SSO}?callback=${process.env.VUE_APP_DOMAIN}`;
};

const logout = ({ commit }) => {
	commit("clearToken");
	Vue.helpers.clearCookie("loginToken");
	Vue.helpers.clearCookie("validUntil");
	window.location.href = `${process.env.VUE_APP_SSO_LOGOUT}`;
};

const getAuthUser = async ({ commit }) => {
	try {
		const { payload } = await authUser.getAuthUser();
		commit("setAuthUser", payload);
	} catch (error) {
		errorHandling.onError(error);
	}
};

export default {
	loadLocalToken,
	setToken,
	clearToken,
	checkToken,
	logout,
	getAuthUser
};
