<template>
  <v-container fluid>
    <div class="d-flex flex-column fill-height flex-grow-1">
      <div class="d-flex">
        <div class="text-h4">Products</div>
        <v-btn
          :to="{ name: 'create-' + routeName }"
          large
          color="red"
          class="white--text ml-auto"
          v-if="permissionToCreateItem"
        >
          Create new
        </v-btn>
      </div>
      <div class="flex-grow-1">
        <t-table
          :headers="headers"
          :namespace="namespace"
          :permissions-namespace="permissionsNamespace"
          :route-name="routeName"
          :id="idValue"
          :delete-store-url="deleteStoreUrl"
        />
      </div>
    </div>
  </v-container>
</template>

<script>
import permissionsMixin from "@/mixins/permissions";

export default {
  name: "AdminProducts",
  components: {
    TTable: () => import("@/components/Core/Table/TTable"),
  },
  mixins: [permissionsMixin],
  data() {
    return {
      routeName: "product",
      namespace: "adminProducts",
      permissionsNamespace: "product",
      idValue: "auctionProductId",
      deleteStoreUrl: "adminProducts/deleteItem",
      headers: [
        {
          text: "Product ID",
          align: "left",
          value: "auctionProductId",
          sortable: true,
        },
        {
          text: "Product name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Description",
          align: "left",
          value: "description",
          width: "500px",
          sortable: true,
        },
        {
          text: "Cost Value",
          align: "left",
          value: "costValue",
          sortable: true,
        },
        {
          text: "Actions",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },

  created() {},
};
</script>

<style></style>
