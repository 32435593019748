<template>
  <v-container fluid>
    <v-row v-if="permissionToAccessDeleteSummaryReport">
      <v-col v-for="item in headerItems" :key="item.title" cols="12" sm="3">
        <header-item
          :title="item.title"
          :amount="item.amount"
          :text="item.text"
          class="flex-grow-1"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" v-if="permissionToAccessDeletionProcessList">
        <v-card flat>
          <v-card-title>User deletion processes</v-card-title>
          <v-card-text class="px-0">
            <t-table
              :headers="tableHeaders"
              :namespace="namespace"
              :permissions-namespace="namespace"
              :route-name="routeName"
              :id="idValue"
              :custom-action-btn="customActionBtn"
              :hide-search="true"
              @custom-action="trigger"
            />
          </v-card-text>
        </v-card>        
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import permissionsMixin from "@/mixins/permissions";
import HeaderItem from "../Dashboard/HeaderItem.vue";
export default {
  components: {
    HeaderItem,
    TTable: () => import("@/components/Core/Table/TTable"),
  },
  mixins: [permissionsMixin],
  data() {
    return {
      latestLogins: [],
      headerItems: {
        finished: {
          title: "FINISHED",
          amount: "",
          text: "Deletions"
        },
        inProcess: {
          title: "IN PROCESS",
          amount: "",
          text: "Deletions"
        },
        withError: {
          title: "WITH ERROR",
          amount: "",
          text: "Deletions"
        },       
      },
      customActionBtn: {
        text: "Run again",
        icon: "mdi-refresh",
      },      
      headers: [
        {
          text: "User delete ID",
          align: "center",
          value: "userDeleteId",
        },
        {
          text: "Status",
          align: "center",
          value: "status",
        },
        {
          text: "Start date",
          align: "center",
          value: "startDate",
        },
        {
          text: "End date",
          align: "center",
          value: "endDate",
        },
        {
          text: "Error",
          align: "center",
          value: "hasError",
        },
      ],
      idValue: "userDeleteId",
      routeName: "admin-gdpr",
      namespace: "adminGdpr",
    };
  },
  async mounted() {
    await this.$store.dispatch("adminGdpr/getSummary");
    this.headerItems.finished.amount = this.summary.finished;
    this.headerItems.inProcess.amount = this.summary.inProcess;
    this.headerItems.withError.amount = this.summary.withError;
  },
  computed: {
    ...mapGetters({
      summary: "adminGdpr/summary",
    }),
    tableHeaders() {
      if (this.permissionToRetriggerDeletionProcess) {
        return [...this.headers, {
          text: "Re-run",
          align: "left",
          value: "customAction",
          sortable: false,
        }]
      }
      return this.headers
    }
  },
  methods: {
    async trigger(item) {
      let text
      if (this.permissionToRetriggerDeletionProcess) {
        const response = await this.$store.dispatch("adminGdpr/retriggerExecution", item.userDeleteId)
        text = (response.status === 200) 
          ? "The process was triggered for running again" 
          : "There was an error triggering the execution" 
      } else {
        text = 'You are not allowed to perform this action'
      }
      this.$store.dispatch("snackbar/show", {
        text
      });
    },
  }
};
</script>