<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <t-form-wrapper
          v-if="formData"
          :form-data="formData"
          :callback-route="callbackRoute"
          :store-url="storeUrl"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    TFormWrapper: () => import("@/components/Form/TFormWrapper")
  },
  name: "CreateRoleGroup",
  data() {
    return {
      formData: null,
      storeUrl: "adminRoleGroups/insertItem",
      callbackRoute: "list-role-group"
    };
  },
  computed: {
    ...mapGetters({
      roles: "adminRoles/items"
    })
  },
  async mounted() {
    await this.$store.dispatch("adminRoles/getAllItems");
    this.formData = this.createFormData();
  },
  methods: {
    createFormData() {
      let formData = [
        {
          stepTitle: "General",
          stepFields: {
            name: {
              type: "text",
              value: "",
              name: "name",
              placeholder: "Enter Role Group Name",
              label: "Role Group Name",
              required: true
            },
            displayName: {
              type: "text",
              value: "",
              name: "displayName",
              placeholder: "Enter Role Group Display Name",
              label: "Role Group Display Name",
              required: true
            },
            description: {
              type: "textarea",
              value: "",
              name: "description",
              placeholder: "Enter Role Group Description",
              label: "Role Group Description",
              required: true
            },
            roleIds: {
              type: "select",
              value: "",
              items: this.roles,
              itemValue: "id",
              itemText: "roleDisplayName",
              name: "roleIds",
              placeholder: "Select roles",
              label: "Roles",
              required: true,
              multiple: true
            }
          }
        }
      ];
      return formData;
    }
  }
};
</script>

<style></style>
