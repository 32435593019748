const setOptions = (state, { show, text, color, timeout, minWidth, action, actionText }) => {
	state.show = show;
	state.text = text;
	state.color = color ? color : "";
	state.timeout = timeout ? timeout : 3000;
	state.minWidth = minWidth ? minWidth : "360";
	state.action = action ? action : false;
	state.actionText = actionText ? actionText : "";
}

export default {
	setOptions
};
