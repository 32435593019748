const show = state => state.show;
const text = state => state.text;
const color = state => state.color;
const timeout = state => state.timeout;
const minWidth = state => state.minWidth;
const action = state => state.action;
const actionText = state => state.actionText;

export default {
	show,
	text,
	color,
	timeout,
	minWidth,
	action,
	actionText
};
