import Vue from "vue";
const setItemsData = (state, value) => {
  state.items = value.result ? value.result : state.items;
  state.totalCount = value.pagination
    ? value.pagination.totalCount
    : state.totalCount;
};

const setLoading = (state, value) => {
  state.loading = value;
};

const setPagination = (state, value) => {
  Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

const setFromDate = (state, value) => {
  state.pagination.fromDate = value;
};

const setToDate = (state, value) => {
  state.pagination.toDate = value;
};

const setReason = (state, value) => {
  state.pagination.reportReason = value;
  state.pagination.page = 1;
};

export default {
  setItemsData,
  setPagination,
  setLoading,
  setFromDate,
  setToDate,
  setReason,
};
