const items = (state) => {
  return state.items;
};
const loading = (state) => state.loading;
const totalCount = (state) => state.totalCount;

export default {
  items,
  loading,
  totalCount,
};
