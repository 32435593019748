<template>
  <v-app class="admin-wrapper">
    <template v-if="myself">
      <v-navigation-drawer
        v-model="drawer"
        mobile-breakpoint="1200"
        app
        clipped
      >
        <v-list dense>
          <v-list-item @click="main()" link>
            <v-list-item-action>
              <v-icon>mdi-view-dashboard-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="users()" link v-if="permissionToViewUsers">
            <v-list-item-action>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="auctions()" link v-if="permissionToViewAuctions">
            <v-list-item-action>
              <v-icon>mdi-store</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Auctions</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="products()" link v-if="permissionToViewProducts">
            <v-list-item-action>
              <v-icon>mdi-widgets</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Products</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="sponsors()" link v-if="permissionToViewSponsors">
            <v-list-item-action>
              <v-icon>mdi-currency-usd</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sponsors</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="partners()" link v-if="permissionToViewPartners">
            <v-list-item-action>
              <v-icon>mdi-handshake-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Partners</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-list-item @click="permissions()" link>
            <v-list-item-action>
              <v-icon>mdi-account-supervisor-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Permissions</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->
          <v-list-item @click="roles()" link v-if="permissionToViewRoles">
            <v-list-item-action>
              <v-icon>mdi-account-supervisor-circle</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Roles</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="roleGroups()"
            link
            v-if="permissionToViewRoleGroups"
          >
            <v-list-item-action>
              <v-icon>mdi-account-tie</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Role groups</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="posts()" link>
            <v-list-item-action>
              <v-icon>mdi-post-outline</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Post moderation</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="reports()" link>
            <v-list-item-action>
              <v-icon>mdi-alert-octagon</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Reports</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            @click="gdpr()"
            link
            v-if="permissionToAccessDeleteSummaryReport"
          >
            <v-list-item-action>
              <v-icon>mdi-account-group</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>GDPR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item @click="countries()" link>
            <v-list-item-action>
              <v-icon>mdi-home-group</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Countries</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            @click="blockedDomains()"
            link
            v-if="permissionToListBlockedDomains"
          >
            <v-list-item-action>
              <v-icon>mdi-block-helper</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Blocked domains</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="auctionBlockedUsers()" link>
            <v-list-item-action>
              <v-icon>mdi-account-cancel</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Auction blocked users</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item @click="logout()" link>
            <v-list-item-action>
              <v-icon>mdi-exit-to-app</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Sign out</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar app clipped-left dense>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
        <v-toolbar-title>
          <router-link to="/">
            <v-img width="80" height="32" :src="require('@/assets/logo.svg')" />
          </router-link>
        </v-toolbar-title>
        <v-spacer />
        <div>
          <v-btn
            @click="openReportsModal()"
            small
            color="red"
            class="white--text ml-auto"
            v-if="permissionToGenerateReports"
          >
            Reports
          </v-btn>
          <reports-modal :show="reportsModal" @close="closeReportsModal" />
        </div>
      </v-app-bar>
      <v-main>
        <v-container class="fill-height align-start" fluid>
          <router-view />
        </v-container>
      </v-main>
    </template>
    <loader v-else />
  </v-app>
</template>

<script>
import permissionsMixin from "@/mixins/permissions";

export default {
  components: {
    Loader: () => import("@/components/Core/Loader"),
    ReportsModal: () => import("@/components/Admin/Reports/ReportsModal"),
  },
  mixins: [permissionsMixin],
  props: {
    q: {
      type: String,
      //   required: true,
    },
  },
  data() {
    return {
      drawer: false,
      reportsModal: false,
    };
  },
  computed: {
    myself() {
      // return this.$store.getters["user/user"];
      return true;
    },
  },
  watch: {},
  methods: {
    logout() {
      this.$store.dispatch("auth/logout");
    },
    auctions() {
      this.$router.push({ name: "list-auction" });
    },
    products() {
      this.$router.push({ name: "list-product" });
    },
    sponsors() {
      this.$router.push({ name: "list-sponsor" });
    },
    partners() {
      this.$router.push({ name: "list-partner" });
    },
    permissions() {
      this.$router.push({ name: "list-permission" });
    },
    roles() {
      this.$router.push({ name: "list-role" });
    },
    roleGroups() {
      this.$router.push({ name: "list-role-group" });
    },
    posts() {
      this.$router.push({ name: "list-post" });
    },
    reports() {
      this.$router.push({ name: "reports" });
    },
    auctionBlockedUsers() {
      this.$router.push({ name: "list-auction-blocked-users" });
    },
    gdpr() {
      this.$router.push({ name: "gdpr" });
    },
    countries() {
      this.$router.push({ name: "countries" });
    },
    blockedDomains() {
      this.$router.push({ name: "list-blocked-domain" });
    },
    main() {
      this.$router.push({ name: "admin" });
    },
    users() {
      this.$router.push({ name: "list-user" });
    },
    openReportsModal() {
      this.reportsModal = true;
    },
    closeReportsModal() {
      this.reportsModal = false;
    },
  },
};
</script>

<style lang="scss">
.admin-wrapper {
  .theme--light.v-list,
  .theme--light.v-sheet,
  .theme--light.v-tabs-bar,
  .theme--light.v-tabs-items {
    background: #fff !important;
  }
}
</style>
