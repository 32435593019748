import AdminApi from "@/api/admin/AdminApi";

export default class AdminUserModerationApi extends AdminApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "identityadm/v1.0/reported-user-moderation";
  }

  async getList(pagination) {
    let url = this.baseEndpoint;
    let response = await this.getData(url, pagination);
    return response;
  }

  async approveReport(payload) {
    const url = this.baseEndpoint + "/accept-report";
    const response = await this.patchData(url, payload);
    return response;
  }

  async rejectReport(userId) {
    const url = this.baseEndpoint + "/reject-report";
    const response = await this.patchData(url, { userId });
    return response;
  }
}
