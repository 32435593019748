import Vue from "vue";
import Vuetify from "vuetify/lib";
Vue.use(Vuetify);

export default new Vuetify({
	customVariables: ["~/assets/sass/variables.scss"],
	theme: {
		dark: !!JSON.parse(localStorage.getItem("theme")),
		options: {
			customProperties: true
		},
		themes: {
			light: {
				fa: "#fafafa",
				ededed: "#ededed",
				d7d7d7: "#d7d7d7",
				vl8b8b8b: "#8b8b8b",
				postBG: "#ffffff",
				postPageBG: "#f8f8f8",
				appBar: "#161c21",
				mainText: "#222",
				secondaryText: "#8b8b8b",
				extText: "#535353",
				extBg: "#f0f0f0",
				extBtn: "#fff",
				extBC: "#dddddd",
				iconColor1: "#fff",
				background: "#f0f1f2",
				primary: "#da192f",
				secondary: "#b0bec5",
				accent: "#8c9eff",
				error: "#b71c1c",
				main: "#ff0000",
				hrHome: "#dddddd",
				postName: "#a0a0a0",
				scrollBtn: "#8b8b8b",
				scrollBtnText: "#fff",
				red: "#f7000b",
				followBtn: "#f0f0f0",
				followBtnText: "#222222",
				nickName: "#222",
				BG: "#fff",
				BGDialog: "#fff",
				followBtnDialog: "#f0f0f0",
				arrowBGMain: "#fff",
				postBtn: "#f0f0f0",
				bubleComment: "#f6f6f6",
				addPostColor: "#f0f0f0",
				commentBg: "#E7E9EB"
			},
			dark: {
				vl8b8b8b: "#737373",
				addPostColor: "#151515",
				bubleComment: "#343434",
				extBtn: "#222",
				extBC: "#2e2e2e",
				extBg: "#000",
				BG: "#222",
				BGDialog: "#424242",
				followBtnDialog: "#222",
				postMainBG: "#151515",
				postBG: "#212121",
				postBtn: "#333",
				nickName: "#ddd",
				postName: "#737373",
				followBtn: "#434343",
				followBtnText: "#f0f0f0",
				appBar: "#161c21",
				mainText: "#8b8b8b",
				secondaryText: "#8b8b8b",
				iconColor1: "#fff",
				background: "#555555",
				primary: "#da192f",
				secondary: "#b0bec5",
				accent: "#8c9eff",
				error: "#b71c1c",
				main: "#b71c1c",
				scrollBtn: "#8b8b8b",
				scrollBtnText: "#fff",
				red: "#f7000b",
				commentBg: "#161C21"
			}
		}
	},
});
