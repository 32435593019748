import Vue from "vue";
import VueRouter from "vue-router";

import Auth from "@/views/Auth";
import AdminWrapper from "@/views/AdminWrapper";
import Dashboard from "@/components/Admin/Dashboard/Dashboard";
import AdminAuctions from "@/components/Admin/Auctions/List";
import CreateAuction from "@/components/Admin/Auctions/Create";
import EditAuction from "@/components/Admin/Auctions/Edit";
import AdminProducts from "@/components/Admin/Products/List";
import CreateProduct from "@/components/Admin/Products/Create";
import EditProduct from "@/components/Admin/Products/Edit";
import AdminSponsors from "@/components/Admin/Sponsors/List";
import CreateSponsor from "@/components/Admin/Sponsors/Create";
import EditSponsor from "@/components/Admin/Sponsors/Edit";
import AdminPartners from "@/components/Admin/Partners/List";
// import Permissions from "@/router/permissions";
import PostModeration from "@/router/postModeration";
import Reports from "@/router/reports";
import AuctionBlockedUsers from "@/router/auctionBlockedUsers";
import Roles from "@/router/roles";
import Users from "@/router/users";
import RoleGroups from "@/router/roleGroups";
import Gdpr from "@/router/gdpr";
import AdminBlockedDomains from "@/components/Admin/BlockedDomains/List";
import CreateBlockedDomain from "@/components/Admin/BlockedDomains/Create";
import Countries from "@/router/countries";

Vue.use(VueRouter);

const routes = [
  {
    path: "/admin",
    component: AdminWrapper,
    props: true,
    children: [
      {
        path: "",
        name: "admin",
        component: Dashboard,
      },
      {
        path: "auctions",
        name: "list-auction",
        component: AdminAuctions,
      },
      {
        path: "create-auction",
        name: "create-auction",
        component: CreateAuction,
      },
      {
        path: "edit-auction/:uid",
        name: "edit-auction",
        props: true,
        component: EditAuction,
      },
      {
        path: "products",
        name: "list-product",
        component: AdminProducts,
      },
      {
        path: "create-product",
        name: "create-product",
        component: CreateProduct,
      },
      {
        path: "edit-product/:uid",
        name: "edit-product",
        props: true,
        component: EditProduct,
      },
      {
        path: "sponsors",
        name: "list-sponsor",
        component: AdminSponsors,
      },
      {
        path: "create-sponsor",
        name: "create-sponsor",
        component: CreateSponsor,
      },
      {
        path: "edit-sponsor/:uid",
        name: "edit-sponsor",
        props: true,
        component: EditSponsor,
      },
      // Partners
      {
        path: "partners",
        name: "list-partner",
        component: AdminPartners,
      },
      // ...Permissions,
      ...Roles,
      ...Users,
      ...RoleGroups,
      ...PostModeration,
      ...Reports,
      ...AuctionBlockedUsers,
      ...Gdpr,
      ...Countries,
      {
        path: "blocked-domains",
        name: "list-blocked-domain",
        component: AdminBlockedDomains,
      },
      {
        path: "create-blocked-domain",
        name: "create-blocked-domain",
        component: CreateBlockedDomain,
      },
    ],
  },
  {
    path: "/",
    name: "auth",
    component: Auth,
  },
  {
    path: "*",
    redirect: "/admin",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
