import AdminApi from "@/api/admin/auctions";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const errorHandling = new ErrorHandling();

const getItems = async ({ commit, state }) => {
	commit("setLoading", true);
	const options = JSON.parse(JSON.stringify(state.pagination));
	Object.keys(options).forEach(function() {
		options["SkipCount"] = (options["page"] - 1) * options["itemsPerPage"];
		options["MaxResultCount"] = options["itemsPerPage"];
		options["sortDesc"] = options["sortDesc"].toString();
		options["sortBy"] = options["sortBy"].toString();
	});
	try {
		const { data } = await adminApi.getList(options);
		commit("setItemsData", data);
		commit("setLoading", false);
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error, { pagination: options });
	}
};

const setPagination = async ({ commit }, payload) => {
	commit("setPagination", payload);
};

const getItem = async ({ commit }, itemId) => {
	commit("setLoading", true);
	try {
		const { data } = await adminApi.getItem(itemId);
		commit("setItemData", data);
		commit("setLoading", false);
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error, { itemId });
	}
};

const insertItem = async ({ commit }, item) => {
	commit("setLoading", true);
	try {
		await adminApi.insertItem(item);
		commit("setLoading", false);
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error, { item });
	}
};

const updateItem = async ({ commit }, item) => {
	commit("setLoading", true);
	try {
		await adminApi.updateItem(item);
		commit("setLoading", false);
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error, { item });
	}
};

const deleteItem = async ({ commit }, item) => {
	commit("setLoading", true);
	try {
		await adminApi.deleteItem(item);
		commit("deleteOnClient", item);
		commit("setLoading", false);
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error, { item });
		throw error;
	}
};

const getUser = async (context, id) => {
	try {
		const { data } = await adminApi.getUser(id);
		return data;
	} catch (error) {
		errorHandling.onError(error, { id });
	}
};

const getLiveAuctions = async ({ commit }) => {
	try {
		const { data: { totalCount } } = await adminApi.getLiveAuctions();
		commit("setNumberOfLiveAuctions", totalCount);
	} catch (error) {
		errorHandling.onError(error);
	}
}

export default {
	getItems,
	getItem,
	setPagination,
	insertItem,
	updateItem,
	deleteItem,
	getUser,
	getLiveAuctions,
};
