<template>
  <v-container fluid>
    <v-row v-if="permissionToViewUsers">
      <v-col v-for="item in headerItems" :key="item.title" cols="12" sm="4">
        <header-item
          :title="item.title"
          :amount="item.amount"
          :text="item.text"
          class="flex-grow-1"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" xl="6" v-if="permissionToViewUsers">
        <latest-logins :latest-logins="latestLogins" />
      </v-col>
      <v-col cols="12" xl="6" v-if="permissionToViewUsers">
        <latest-signups :latest-signups="latestSignups" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import permissionsMixin from "@/mixins/permissions";
import HeaderItem from "./HeaderItem.vue";
import LatestLogins from "./LatestLogins.vue";
import LatestSignups from "./LatestSignups.vue";
export default {
  components: {
    HeaderItem,
    LatestLogins,
    LatestSignups,
  },
  mixins: [permissionsMixin],
  data() {
    return {
      latestLogins: [],
      latestSignups: [],
      headerItems: {
        logins: {
          title: "LOGINS",
          amount: "",
          text: "Last 7 days",
        },
        signups: {
          title: "NEW SIGNUPS",
          amount: "",
          text: "Last 7 days",
        },
        auction: {
          title: "AUCTIONS",
          amount: "",
          text: "Number of live auctions",
        },
      },
    };
  },
  async mounted() {
    await this.$store.dispatch("adminAuctions/getLiveAuctions");
    this.headerItems.auction.amount = this.numberOfLiveAuctions;
    if (this.permissionToViewUsers) {
      const {
        usersActivities: latestSignups,
        totalCount: signupsCountLastSevenDays,
      } = await this.$store.dispatch("adminUsers/getLatestSignups");
      this.latestSignups = latestSignups;
      this.headerItems.signups.amount = signupsCountLastSevenDays.toString();

      const {
        usersActivities: latestLogins,
        totalCount: loginsCountLastSevenDays,
      } = await this.$store.dispatch("adminUsers/getLatestLogins");
      this.latestLogins = latestLogins;
      this.headerItems.logins.amount = loginsCountLastSevenDays.toString();
    }
  },
  computed: {
    ...mapGetters({
      numberOfLiveAuctions: "adminAuctions/numberOfLiveAuctions",
    }),
  },
};
</script>
