import Vue from "vue";
import Vuex from "vuex";

import AdminProducts from "./admin/products";
import AdminAuctions from "./admin/auctions";
import AdminSponsors from "./admin/sponsors";
import AdminPartners from "./admin/partners";
import AdminCountries from "./admin/countries";
import AdminUsers from "./admin/users";
import AdminPermissions from "./admin/permissions";
import AdminRoles from "./admin/roles";
import AdminDonations from "./admin/donations";
import PostModeration from "./admin/postModeration";
import AdminReportedPostModeration from "./admin/reportedPostModeration";
import AdminReportedCommentModeration from "./admin/reportedCommentModeration";
import AdminReportedUserModeration from "./admin/reportedUserModeration";
import AuctionBlockedUsers from "./admin/auctionBlockedUsers";
import Reasons from "./admin/reasons";
import AdminRoleGroups from "./admin/roleGroups";
import AdminFileUpload from "./admin/fileUpload";
import AdminReports from "./admin/reports";
import AdminGdpr from "./admin/gdpr";
import AdminBlockedDomains from "./admin/blockedDomains";
import Snackbar from "./snackbar";
import Auth from "./auth";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    adminAuctions: AdminAuctions,
    adminProducts: AdminProducts,
    adminUsers: AdminUsers,
    adminSponsors: AdminSponsors,
    adminCountries: AdminCountries,
    adminPartners: AdminPartners,
    adminPermissions: AdminPermissions,
    adminRoles: AdminRoles,
    adminDonations: AdminDonations,
    postModeration: PostModeration,
    adminReportedPostModeration: AdminReportedPostModeration,
    adminReportedCommentModeration: AdminReportedCommentModeration,
    adminReportedUserModeration: AdminReportedUserModeration,
    auctionBlockedUsers: AuctionBlockedUsers,
    reasons: Reasons,
    adminRoleGroups: AdminRoleGroups,
    adminFileUpload: AdminFileUpload,
    adminReports: AdminReports,
    adminGdpr: AdminGdpr,
    adminBlockedDomains: AdminBlockedDomains,
    snackbar: Snackbar,
    auth: Auth,
  },
});
