import AdminUsers from "@/components/Admin/Users/List";
import ShowUser from "@/components/Admin/Users/Show";

export default [
  {
    path: "users",
    name: "list-user",
    component: AdminUsers
  },
  {
    path: "show-user/:uid",
    name: "show-user",
    props: true,
    component: ShowUser
  },
];
