import AdminApi from "@/api/admin/AdminApi";

export default class AdminReportedPostsApi extends AdminApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "identityadm/v1.0/reported-post-moderation/";
  }

  async getList(pagination) {
    let url = this.baseEndpoint;
    let response = await this.getData(url, pagination);
    return response;
  }

  async acceptReport(item) {
    let url = this.baseEndpoint + `accept-report`;
    let response = await this.patchData(url, item);
    return response;
  }

  async rejectReport(item) {
    let url = this.baseEndpoint + `reject-report`;
    let response = await this.patchData(url, item);
    return response;
  }
}
