import AdminApi from "@/api/admin/donations";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const errorHandling = new ErrorHandling();

const getDonations = async ({ commit }, userProfileId) => {
  try {
    const { data } = await adminApi.getUserDonation(userProfileId);
    commit("setDonationsData", data);
    return data;
  } catch (error) {
    errorHandling.onError(error);
    throw new Error(error);
  }
};

const updateDonations = async ({ commit, dispatch }, { userId, payload }) => {
  commit("setLoading", true);
  try {
    await adminApi.updateUserDonation(userId, payload);

    dispatch(
      "snackbar/show",
      {
        text: "Update successful!",
        color: "success",
      },
      { root: true }
    );
  } catch (error) {
    dispatch(
      "snackbar/show",
      {
        text: "Update failed!",
        color: "error",
      },
      { root: true }
    );
    errorHandling.onError(error);
    throw new Error(error);
  } finally {
    commit("setLoading", false);
  }
};

export default {
  getDonations,
  updateDonations,
};
