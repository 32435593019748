import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
	token: null,
	authUser: null,
	validUntil: null,
	userPermissions: []
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
};
