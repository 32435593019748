import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  items: [],
  loading: false,
  resultLength: 10,
  pagination: {
    page: 1,
    itemsPerPage: 10,
    moderationResult: 2,
    Name: "",
    fromDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleString(
      "fr-CA",
      {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      }
    ),
    hasNext: true,
    hasPrevious: false,
    toDate: "",
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
