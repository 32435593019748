import AdminApi from "@/api/admin/AdminApi";

export default class AdminGdprApi extends AdminApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "identityadm/v1.0/gdpr/";
  }

  async getSummary() {
    let url = this.baseEndpoint + `summary`;
    let response = await this.getData(url);
    return response;
  }

  async getDeletionsInExecution() {
    let url = this.baseEndpoint + `in-execution`;
    let response = await this.getData(url);
    return response;
  }

  async retriggerExecution(userDeleteId) {
    let url = this.baseEndpoint + userDeleteId + `/retrigger-execution`;
    let response = await this.insertOrUpdateData(url);
    return response;
  }
}
