<template>
  <v-container class="fill-height align-start" fluid>
    <v-row v-if="user">
      <v-col cols="12">
        <v-card class="mx-auto my-5 py-5 px-10">
          <v-list-item three-line>
            <v-list-item-content>
              <div class="overline mb-4">Provider - {{ user.provider }}</div>
              <v-list-item-title class="headline mb-1">{{
                user.email
              }}</v-list-item-title>
              <v-list-item-subtitle class="mb-2"
                >{{ user.firstName }}&nbsp;{{
                  user.lastName
                }}</v-list-item-subtitle
              >
            </v-list-item-content>
            <v-list-item-avatar size="80">
              <avatar class="admin-avatar" :size="80" :user="user" />
            </v-list-item-avatar>
          </v-list-item>
          <v-card-actions class="align-center">
            <div>
              <v-list-item-subtitle
                >e-mail sent: {{ emailSent }}</v-list-item-subtitle
              >
              <v-list-item-subtitle>
                e-mail status:
                <v-icon v-if="!user.emailVerified" color="red"
                  >mdi-email-alert-outline</v-icon
                >
                <v-icon v-else color="green">mdi-email-check-outline</v-icon>
              </v-list-item-subtitle>
            </div>
            <v-spacer></v-spacer>
            <!-- Block user from auctioning -->
            <v-btn
              :disabled="user.blockedForAuction"
              @click="openBlockForAuctionModal()"
              color="red"
              class="white--text ml-auto"
            >
              Block for auction
            </v-btn>
            <block-for-auction-modal
              :show="blockForAuctionModal"
              :uid="uid"
              :user="user"
              @close="closeBlockForAuctionModal"
            />
            <!-- Delete user -->
            <v-btn
              :disabled="!hasDeletePermission"
              @click="showDeleteDialog()"
              color="red"
              class="white--text ml-3"
            >
              Delete
            </v-btn>
            <t-dialog-wrapper
              :show="deleteDialog"
              @close-dialog="closeDeleteDialog"
            >
              <t-basic-dialog
                btn-text="Delete"
                :title="'Delete user'"
                @confirm="deleteUser"
              >
                <div class="text-body-2 grey--text">
                  Do you really want to delete this user?
                </div>
              </t-basic-dialog>
            </t-dialog-wrapper>
            <v-select
              v-if="permissionToUpdateAccountType"
              v-model="accountType"
              :items="accountTypes"
              outlined
              class="max-width-300 px-2"
              hide-details
              label="Account type"
              placeholder="Account type"
            ></v-select>
            <v-autocomplete
              v-if="selectInterestsAvailable"
              v-model="selectedInterests"
              :items="allInterests"
              outlined
              class="max-width-300 px-2"
              hide-details
              return-object
              item-text="name"
              :loading="interestsLoading"
              :disabled="interestsLoading"
              label="Select tatatuer interests"
              placeholder="Select tatatuer interests"
            ></v-autocomplete>
            <v-tooltip bottom v-if="permissionToBlockUser">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="switchBlocked()"
                  :color="blocked ? 'red' : 'green'"
                  fab
                  dark
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                  depressed
                >
                  <v-icon>{{
                    blocked ? "mdi-lock-outline" : "mdi-lock-open-outline"
                  }}</v-icon>
                </v-btn>
              </template>
              <span>{{ blocked ? "Unblock user" : "Block user" }}</span>
            </v-tooltip>
            <!-- <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="deleteUser()"
                  color="red"
                  fab
                  dark
                  depressed
                  class="ma-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>
              </template>
              <span>Delete user</span>
            </v-tooltip> -->
          </v-card-actions>
        </v-card>
      </v-col>
      <v-col cols="12">
        <v-tabs v-model="activeTab">
          <v-tab v-if="permissionToViewUserActivity">User Activity</v-tab>
          <v-tab
            v-if="
              permissionToUpdateUserRoles || permissionToUpdateUserRoleGroups
            "
            >User Roles</v-tab
          >
          <v-tab v-if="permissionToUpdateUserDonations">User Donations</v-tab>
        </v-tabs>
        <template v-if="activeTab === 0 && permissionToViewUserActivity">
          <v-card>
            <v-data-table
              v-if="!itemsLoading"
              :headers="headers"
              :items="items"
              :loading="itemsLoading"
              :options.sync="pagination"
              :server-items-length="itemsTotalCount"
            >
              <template v-slot:item.date="{ item }">
                <moments-ago :unix="true" :date="item.date" />
              </template>
              <template v-slot:item.reason="{ item }">
                <span v-if="item.result == 'OK'">OK</span>
                <span v-else>{{ readError(item.reason) }}</span>
              </template>
            </v-data-table>
            <v-layout v-else fill-height align-center justify-center ma-0>
              <v-skeleton-loader
                v-slot:progress
                width="100%"
                v-bind="{ boilerplate: true, elevation: 2 }"
                type="table-thead, table-tbody, table-tfoot"
              ></v-skeleton-loader>
            </v-layout>
          </v-card>
        </template>
        <template
          v-if="
            activeTab === 1 &&
              (permissionToUpdateUserRoles || permissionToUpdateUserRoleGroups)
          "
        >
          <v-card class="pa-4">
            <div class="d-flex" v-if="permissionToUpdateUserRoles">
              <v-autocomplete
                @change="addRole"
                v-model="userRole"
                :items="availableRoles"
                item-value="id"
                item-text="roleDisplayName"
                outlined
                class="max-width-300 px-2"
                hide-details
                label="Add role to user"
                placeholder="Add role to user"
                :loading="rolesLoading"
                :disabled="rolesLoading"
              ></v-autocomplete>
              <div>
                <v-chip
                  close
                  @click:close="removeRole(item.id)"
                  v-for="item in addedRoles"
                  :key="item.id"
                  class="mr-2"
                >
                  {{ item.roleDisplayName }}
                </v-chip>
              </div>
            </div>
            <v-divider
              class="my-4"
              v-if="
                permissionToUpdateUserRoles && permissionToUpdateUserRoleGroups
              "
            ></v-divider>
            <div class="d-flex" v-if="permissionToUpdateUserRoleGroups">
              <v-select
                @change="addRoleGroup"
                v-model="userRoleGroup"
                :items="availableRoleGroups"
                item-value="id"
                item-text="displayName"
                outlined
                class="max-width-300 px-2"
                hide-details
                label="Add role group to user"
                placeholder="Add role group to user"
                :loading="roleGroupsLoading"
                :disabled="roleGroupsLoading"
              ></v-select>
              <div>
                <v-chip
                  close
                  @click:close="removeRoleGroup(item.id)"
                  v-for="item in addedRoleGroups"
                  :key="item.id"
                  class="mr-2"
                >
                  {{ item.displayName }}
                </v-chip>
              </div>
            </div>
          </v-card>
        </template>
        <template v-if="activeTab === 2 && permissionToUpdateUserDonations">
          <v-card class="pa-5">
            <p>User donations</p>
            <div class="d-flex align-center">
              <v-switch
                v-model="fixedDonator"
                :label="'Fixed donator'"
              ></v-switch>
              <div class="my-5">
                <v-divider></v-divider>
              </div>
              <div class="d-flex align-center justify-center ml-10">
                <v-btn
                  fab
                  small
                  :color="'#222'"
                  :disabled="minusBtnDisabled"
                  class="mr-5"
                  :style="{ backgroundColor: 'transparent' }"
                  @click="minus"
                >
                  <v-icon color="#222">mdi-minus-circle-outline</v-icon>
                </v-btn>
                <div class="d-flex flex-column">
                  <span
                    class="mb-2"
                    :style="{ fontSize: '12px', color: 'rgba(0, 0, 0, 0.6)' }"
                    >Donation percentage</span
                  >
                  <div
                    class="px-7 d-flex align-center"
                    :style="{
                      fontSize: '24px',
                      color: minusBtnDisabled ? '#888' : '#222',
                    }"
                  >
                    {{ donationPercentage }} %
                  </div>
                </div>

                <v-btn
                  fab
                  small
                  :color="'#222'"
                  :disabled="plusBtnDisabled"
                  class="ml-5"
                  :style="{ backgroundColor: 'transparent' }"
                  @click="plus"
                >
                  <v-icon color="#222">mdi-plus-circle-outline</v-icon>
                </v-btn>
              </div>
            </div>
            <v-btn
              :loading="donationsLoading"
              :disabled="!donationPercentageValid"
              color="red"
              class="white--text mt-5"
              @click="saveDonationsData()"
            >
              Save
            </v-btn>
          </v-card>
        </template>
      </v-col>
    </v-row>
    <v-row v-else class="fill-height">
      <v-col cols="12" class="fill-height">
        <loader />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import permissionsMixin from "@/mixins/permissions";

export default {
  name: "AdminShowUser",
  components: {
    MomentsAgo: () => import("@/components/Core/MomentsAgo"),
    Avatar: () => import("@/components/Core/Avatar"),
    Loader: () => import("@/components/Core/Loader"),
    BlockForAuctionModal: () =>
      import("@/components/Admin/Auctions/BlockForAuctionModal"),
    TDialogWrapper: () => import("@/components/Dialog/TDialogWrapper"),
    TBasicDialog: () => import("@/components/Dialog/TBasicDialog"),
  },
  mixins: [permissionsMixin],
  props: {
    uid: {
      type: String,
    },
  },
  data() {
    return {
      activeTab: 0,
      accountTypes: [],
      localAccountType: "",
      userRole: null,
      userRoleGroup: null,
      headers: [
        {
          text: "Type",
          align: "start",
          value: "type",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          value: "reason",
          sortable: false,
        },
        {
          text: "IP",
          align: "center",
          value: "ipAddress",
          sortable: false,
        },
        {
          text: "When",
          align: "end",
          value: "date",
          sortable: false,
        },
      ],
      blockForAuctionModal: false,
      deleteDialog: false,
      fixedDonator: false,
      donationPercentage: 0,
      donationRules: [
        (v) => (v && v >= 0) || "Value should be between 0 and 100",
        (v) => (v && v <= 100) || "Value should be between 0 and 100",
      ],
    };
  },
  computed: {
    ...mapGetters({
      user: "adminUsers/item",
      loading: "adminUsers/loading",
      items: "adminUsers/userActivity",
      itemsLoading: "adminUsers/userActivityLoading",
      itemsTotalCount: "adminUsers/userActivityTotalCount",
      roles: "adminRoles/items",
      rolesLoading: "adminUsers/userRolesLoading",
      roleGroups: "adminRoleGroups/items",
      roleGroupsLoading: "adminUsers/userRoleGroupsLoading",
      allInterests: "adminUsers/allInterests",
      interestsLoading: "adminUsers/allInterestsLoading",
      userInterests: "adminUsers/userInterests",
      authUserPermissions: "auth/userPermissions",
      userDonations: "adminDonations/donations",
      donationsLoading: "adminDonations/loading",
    }),
    pagination: {
      get() {
        return this.$store.getters["adminUsers/userActivityPagination"];
      },
      set(value) {
        this.$store.dispatch("adminUsers/setUserActivityPagination", {
          ...value,
          userid: this.uid,
        });
      },
    },
    accountType: {
      get() {
        return this.user.accountType;
      },
      set(value) {
        this.localAccountType = value;
        this.changeAccountType(value);
      },
    },
    selectedInterests: {
      get() {
        return this.userInterests;
      },
      set(value) {
        this.changeUserInterests(value);
      },
    },
    selectInterestsAvailable() {
      return this.localAccountType == "tatatuer";
    },
    availableRoles() {
      const userRoles = this.user.roles || [];
      return this.roles.filter((role) => {
        return userRoles.indexOf(role.id) < 0;
      });
    },
    addedRoles() {
      const userRoles = this.user.roles || [];
      return this.roles.filter((role) => {
        return userRoles.indexOf(role.id) >= 0;
      });
    },
    availableRoleGroups() {
      const userRoleGroups = this.user.roleGroups || [];
      return this.roleGroups.filter((role) => {
        return userRoleGroups.indexOf(role.id) < 0;
      });
    },
    addedRoleGroups() {
      const userRoleGroups = this.user.roleGroups || [];
      return this.roleGroups.filter((role) => {
        return userRoleGroups.indexOf(role.id) >= 0;
      });
    },
    blocked() {
      return this.user.state === "blocked";
    },
    isAdmin() {
      return this.user.isAdmin;
    },
    emailSent() {
      return this.user.emailSent === 0
        ? "N/A"
        : this.$moment.unix(this.user.emailSent).format("DD.MM.YYYY");
    },
    hasDeletePermission() {
      const hasDeletePermission = this.authUserPermissions.find((item) => {
        return item.permissionName === "user-delete";
      });

      return hasDeletePermission;
    },
    donationsChanged() {
      let donationsChanged = false;

      if (this.donationPercentage === this.userDonations.donationPercentage) {
        donationsChanged = false;
      } else {
        donationsChanged = true;
      }
      return donationsChanged;
    },
    donationPercentageValid() {
      return this.donationPercentage >= 0 && this.donationPercentage <= 100;
    },
    minusBtnDisabled() {
      return Number(this.donationPercentage) < 1;
    },
    plusBtnDisabled() {
      return Number(this.donationPercentage) > 99;
    },
  },
  async created() {
    await this.$store.dispatch("adminUsers/getItem", this.uid);
    this.localAccountType = this.user.accountType;
    this.accountTypes = await this.$store.dispatch(
      "adminUsers/getAccountTypes"
    );
    if (!this.roles.length) {
      this.$store.dispatch("adminRoles/getAllItems");
    }
    if (!this.roleGroups.length) {
      this.$store.dispatch("adminRoleGroups/getAllItems");
    }
    await this.$store.dispatch("adminUsers/getAllInterests");

    if (this.permissionToUpdateUserDonations) {
      this.$store.dispatch("adminDonations/getDonations", this.uid).then(() => {
        this.donationPercentage = this.userDonations.donationPercentage;
        this.fixedDonator = this.userDonations.fixedDonator;
      });
    }
  },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    readError(val) {
      return val;
    },
    async switchBlocked() {
      const str = !this.blocked ? "block" : "unblock";
      const bool = await confirm("Are you sure to " + str + " user?");
      if (bool) {
        await this.$store.dispatch("adminUsers/setUserState", {
          block: !this.blocked,
          userId: this.uid,
        });
      }
    },
    async changeAccountType(type) {
      await this.$store.dispatch("adminUsers/changeUserAccountType", {
        userId: this.uid,
        accountType: type,
      });
    },
    async changeUserInterests(interests) {
      await this.$store.dispatch("adminUsers/setUserInterests", {
        userProfileId: this.uid,
        interests: [interests],
      });
    },
    // async deleteUser() {
    //   var bool = await confirm("Are you sure you want delete this user?");
    //   if (bool) {
    //     await this.$store.dispatch("adminUsers/deleteItem", this.uid);
    //     this.$router.push({ name: "admin" });
    //   }
    // },
    async getData() {
      await this.$store.dispatch("adminUsers/getUserActivity");
    },
    addRole(val) {
      this.$store.dispatch("adminUsers/addRole", {
        userId: this.uid,
        roleId: val,
      });
      this.$nextTick(() => {
        this.userRole = 0;
      });
    },
    removeRole(val) {
      this.$store.dispatch("adminUsers/removeRole", {
        userId: this.uid,
        roleId: val,
      });
    },
    addRoleGroup(val) {
      this.$store.dispatch("adminUsers/addRoleGroup", {
        userId: this.uid,
        roleGroupId: val,
      });
      this.$nextTick(() => {
        this.userRoleGroup = 0;
      });
    },
    removeRoleGroup(val) {
      this.$store.dispatch("adminUsers/removeRoleGroup", {
        userId: this.uid,
        roleGroupId: val,
      });
      this.$nextTick(() => {
        this.userRoleGroup = 0;
      });
    },
    saveDonationsData() {
      const isDonator = this.donationPercentage > 0;

      const donationsPayload = {
        isDonator: isDonator,
        donationPercentage: this.donationPercentage,
        fixedDonator: this.fixedDonator,
      };

      this.$store.dispatch("adminDonations/updateDonations", {
        userId: this.uid,
        payload: donationsPayload,
      });
    },
    openBlockForAuctionModal() {
      this.blockForAuctionModal = true;
    },
    closeBlockForAuctionModal() {
      this.blockForAuctionModal = false;
    },
    async deleteUser() {
      await this.$store.dispatch("adminUsers/deleteUser", this.uid);
      await this.closeDeleteDialog();
      this.$store.dispatch("snackbar/show", {
        text: "User successfully deleted",
      });
      this.$router.push({ name: "list-user" });
    },
    showDeleteDialog() {
      this.deleteDialog = true;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    plus() {
      this.donationPercentage =
        Number(this.donationPercentage) + 10 < 100
          ? Number(this.donationPercentage) + 10
          : 100;
    },
    minus() {
      this.donationPercentage =
        Number(this.donationPercentage) - 10 > 0
          ? Number(this.donationPercentage) - 10
          : 0;
    },
  },
};
</script>
<style>
.max-width-300 {
  max-width: 300px;
}
</style>
