import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
	items: [],
	item: {},
	loading: false,
	totalCount: 0,
	numberOfLiveAuctions: null,
	pagination: {
		status: "",
		page: 1,
		itemsPerPage: 10,
		sortBy: ["status"],
		sortDesc: [true],
		desc: true,
		mustSort: true,
		startDateFrom: "",
		Name: "",
		startDateTo: ""
	}
};

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
};
