import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  items: [],
  loading: false,
  // totalCount: 0,
  pagination: {
    SkipCount: "",
    MaxResultCount: "",
    BlockedBy: "",
    Name: "",
    StartDateFrom: "",
    StartDateTo: "",
  },
};

export default {
  namespaced: true,
  state,
  actions,
  getters,
  mutations,
};
