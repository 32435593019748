import Vue from "vue";
const setItemsData = (state, value) => {
	state.items = value.items ? value.items : state.items;
	state.totalCount = value.totalCount ? value.totalCount : state.totalCount;
};

const setItemData = (state, value) => {
	state.item = value;
};

const deleteOnClient = (state, payload) => {
	const i = state.items.map(item => item.auctionId).indexOf(payload.id);
	state.items.splice(i, 1);
};

const setLoading = (state, value) => {
	state.loading = value;
};

const setPagination = (state, value) => {
	Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

const setNumberOfLiveAuctions = (state, value) => {
	state.numberOfLiveAuctions = value;
}

export default {
	setItemsData,
	deleteOnClient,
	setItemData,
	setPagination,
	setLoading,
	setNumberOfLiveAuctions,
};
