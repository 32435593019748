import AdminApi from "@/api/admin/AdminApi";

export default class AdminRolesApi extends AdminApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "identityadm/v1.0/roles/";
	}

	async getList(pagination) {
		let url = this.baseEndpoint + "search/";
		let response = await this.getData(url, pagination);
		return response;
	}

	async getAllItems() {
		let url = this.baseEndpoint;
		let response = await this.getData(url);
		return response;
	}

	async getItem(id) {
		let url = this.baseEndpoint + id;
		let response = await this.getData(url);
		return response;
	}

	async insertItem(item) {
		let url = this.baseEndpoint;
		let response = await this.insertOrUpdateData(url, item);
		return response;
	}

	async updateItem(item) {
		let url = this.baseEndpoint;
		let response = await this.putData(url, item);
		return response;
	}

	async deleteItem(id) {
		let url = this.baseEndpoint + id;
		let response = await this.deleteData(url, null, true);
		return response;
	}
}
