import AdminApi from "@/api/admin/AdminApi";

export default class AdminReportsApi extends AdminApi {
	constructor() {
		super();
		this.baseEndpoint = this.domain + "identityadm/v1.0/reports/";
	}

	async generateRegisteredUsersReport(reportData) {
		let url = this.baseEndpoint + "registered-users";
		let response = await this.insertOrUpdateData(url, reportData);
		return response;
	}
	
	async generateFraudUserReport(reportData) {
		let url = this.baseEndpoint + "fraud-user";
		let response = await this.insertOrUpdateData(url, reportData);
		return response;
	}
}
