import Vue from "vue";
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import store from "@/store";

export default class Api {
	// constructor() {
	// 	this.refreshTokenRequest = null;
	// 	this.tokenIsRefreshing = false;
	// }

	async getToken() {
		if (!this.tokenIsValid()) {
			// if ( !this.tokenIsRefreshing ) {
			// 	await this.refreshToken();
			// } else {
			// 	await this.refreshTokenRequest;
			// }
			store.dispatch("auth/clearToken");
		}
		return Vue.helpers.getCookie("loginToken");
	}

	// async refreshToken() {
	// 	this.tokenIsRefreshing = true;
	// 	let refreshTokenResolve = null;
	// 	this.refreshTokenRequest = new Promise( resolve => {
	// 		refreshTokenResolve = resolve;
	// 	} );
	// 	await store.dispatch( "auth/refreshToken" );
	// 	refreshTokenResolve();
	// 	this.tokenIsRefreshing = false;
	// }

	tokenIsValid() {
		const validUntil = Vue.helpers.getCookie("validUntil");
		return moment.unix(validUntil).diff(moment().valueOf(), "minutes") > 0;
	}

	getLang() {
		return localStorage.getItem("locale")
			? localStorage.getItem("locale")
			: "en";
	}

	getDate(webDate = true) {
		let dateStr = moment().format("YYYY-MM-DDTHH:mm:ss.SSSZZ");
		if (webDate) {
			dateStr += "webDate";
		}
		return dateStr;
	}

	getXDeviceId() {
		let xDeviceId = localStorage.getItem("xDeviceId");
		if (!xDeviceId) {
			xDeviceId = uuidv4();
			localStorage.setItem("xDeviceId", xDeviceId);
		}
		return xDeviceId;
	}
}
