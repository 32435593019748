<template>
  <v-container fluid>
    <div class="d-flex flex-column fill-height flex-grow-1">
      <div class="d-flex">
        <div class="text-h4">Auctions</div>
        <v-btn
          :to="{ name: 'create-' + routeName }"
          large
          color="red"
          class="white--text ml-auto"
          v-if="permissionToCreateItem"
        >
          Create new
        </v-btn>
      </div>
      <div class="flex-grow-1">
        <t-table
          :headers="headers"
          :namespace="namespace"
          :permissions-namespace="permissionsNamespace"
          :route-name="routeName"
          :id="idValue"
          :delete-store-url="deleteStoreUrl"
          :custom-action-btn="customActionBtn"
          @custom-action="viewWinnerInfo"
        />
      </div>
      <t-dialog-wrapper
        :show="winnerInfoModal"
        width="600"
        @close-dialog="closeWinnerInfoModal"
      >
        <v-card>
          <v-card-title class="flex-column align-stretch px-0 pb-0">
            <div class="ml-4 mb-2">Auction Winner Info</div>
            <v-divider></v-divider>
          </v-card-title>
          <v-card-text>
            <div class="text-h6 mt-4" v-if="!winnerUserInfo">Not available</div>
            <div v-else>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Full Name: </span
                >{{ winnerUserInfo.fullName || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Email: </span
                >{{ winnerUserInfo.emailAddress || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Address: </span
                >{{ winnerUserInfo.address || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Apartment: </span
                >{{ winnerUserInfo.apartment || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Post Code: </span
                >{{ winnerUserInfo.postcode || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">City: </span
                >{{ winnerUserInfo.city || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">State: </span
                >{{ winnerUserInfo.state || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Country: </span
                >{{ winnerUserInfo.countryCode || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Phone Number: </span
                >{{ winnerUserInfo.phoneNumber || "-" }}
              </div>
            </div>
          </v-card-text>
        </v-card>
      </t-dialog-wrapper>
    </div>
  </v-container>
</template>

<script>
import permissionsMixin from "@/mixins/permissions";

export default {
  name: "AdminAuctions",
  components: {
    TTable: () => import("@/components/Core/Table/TTable"),
    TDialogWrapper: () => import("@/components/Dialog/TDialogWrapper"),
  },
  mixins: [permissionsMixin],
  data() {
    return {
      routeName: "auction",
      namespace: "adminAuctions",
      permissionsNamespace: "auction",
      idValue: "auctionId",
      deleteStoreUrl: "adminAuctions/deleteItem",
      customActionBtn: {
        text: "View",
        icon: "mdi-account-outline",
      },
      winnerInfoModal: false,
      winnerUserInfo: null,
      headers: [
        {
          text: "Auction ID",
          align: "left",
          value: "auctionId",
          sortable: true,
        },
        {
          text: "Auction name",
          align: "left",
          value: "name",
          sortable: true,
        },
        {
          text: "Auction description",
          align: "left",
          value: "description",
          width: "200px",
          sortable: true,
        },
        {
          text: "Starting Bid",
          align: "left",
          value: "startingBid",
          sortable: true,
        },
        {
          text: "Status",
          align: "left",
          value: "status",
          sortable: true,
        },
        {
          text: "Checked Out",
          align: "left",
          value: "winnerCheckedOut",
          sortable: true,
        },
        {
          text: "Winner Info",
          align: "left",
          value: "customAction",
          sortable: false,
        },
        {
          text: "Actions",
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  methods: {
    async viewWinnerInfo({ highestBidUserId }) {
      if (!highestBidUserId) {
        this.winnerUserInfo = null;
        this.winnerInfoModal = true;
        return;
      }
      const { walletOwner } = await this.$store.dispatch(
        "adminAuctions/getUser",
        highestBidUserId
      );
      this.winnerUserInfo = walletOwner;
      this.winnerInfoModal = true;
    },
    closeWinnerInfoModal() {
      this.winnerInfoModal = false;
    },
  },
};
</script>

<style></style>
