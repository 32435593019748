const setDonationsData = (state, value) => {
  state.donations = value;
};

const setLoading = (state, value) => {
  state.loading = value;
};

export default {
  setDonationsData,
  setLoading,
};
