<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <t-form-wrapper
          v-if="formData"
          :form-data="formData"
          :callback-route="callbackRoute"
          :store-url="storeUrl"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  components: {
    TFormWrapper: () => import("@/components/Form/TFormWrapper")
  },
  name: "CreateSponsor",
  data() {
    return {
      formData: null,
      storeUrl: "adminSponsors/insertItem",
      callbackRoute: "list-sponsor"
    };
  },
  mounted() {
    this.formData = this.createFormData();
  },
  methods: {
    createFormData() {
      let formData = [
        {
          stepTitle: "General",
          stepFields: {
            name: {
              type: "text",
              value: "",
              name: "name",
              placeholder: "Enter Sponsor Name",
              label: "Name",
              required: true
            },
            title: {
              type: "text",
              value: "",
              name: "title",
              placeholder: "Enter Sponsor Title",
              label: "Title",
              required: true
            },
            description: {
              type: "textarea",
              value: "",
              name: "description",
              placeholder: "Enter Sponsor Description",
              label: "Description",
              required: true
            }
          }
        },
        {
          stepTitle: "Media files",
          stepFields: {
            logo: {
              type: "fileUpload",
              showPasteUrl: true,
              placeholder: "Upload file",
              label: "Upload file",
              multiple: false,
              convertToString: true,
              returnObject: false,
              allowedExtensions: ".jpg, .jpeg, .png, .webp, .gif",
              value: [""]
            }
          }
        }
      ];
      return formData;
    }
  }
};
</script>

<style></style>
