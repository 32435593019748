<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <loader v-if="loading" />
        <t-form-wrapper
          v-else
          :form-data="auctionFormData"
          :callback-route="callbackRoute"
          :store-url="storeUrl"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    TFormWrapper: () => import("@/components/Form/TFormWrapper"),
    Loader: () => import("@/components/Core/Loader"),
  },
  name: "EditProduct",
  data() {
    return {
      auctionFormData: null,
      loading: true,
      storeUrl: "adminProducts/updateItem",
      callbackRoute: "list-product",
    };
  },
  computed: {
    ...mapGetters({
      product: "adminProducts/item",
    }),
  },
  async mounted() {
    const { uid } = this.$route.params;
    await this.$store.dispatch("adminProducts/getItem", uid);
    this.auctionFormData = this.createFormData();
    this.loading = false;
  },
  methods: {
    createFormData() {
      let {
        auctionProductId,
        name,
        description,
        costValue,
        auctionMedia,
        bidIncrement,
      } = this.product;
      auctionMedia =
        auctionMedia.length == 0
          ? [
              {
                identifier: "",
                mediaType: "",
                mediaUrl: "",
              },
            ]
          : auctionMedia;
      let formData = [
        {
          stepTitle: "General",
          stepFields: {
            auctionProductId: {
              type: "text",
              disabled: true,
              value: auctionProductId,
              placeholder: "Product ID",
              label: "ID",
              required: true,
            },
            name: {
              type: "text",
              value: name,
              placeholder: "Enter Product Name",
              label: "Name",
              required: true,
            },
            description: {
              type: "textarea",
              value: description,
              placeholder: "Enter Product Description",
              label: "Description",
              required: true,
            },
            costValue: {
              type: "number",
              value: costValue.toString(), //number
              placeholder: "Enter product value",
              label: "Value",
              required: true,
            },
          },
        },
        {
          stepTitle: "Media files",
          stepFields: {
            auctionMedia: {
              type: "fileUpload",
              showPasteUrl: true,
              placeholder: "Upload file",
              label: "Upload file",
              multiple: true,
              value: auctionMedia.map((item) => {
                return {
                  identifier: item.identifier,
                  mediaType: item.mediaType,
                  mediaUrl: item.mediaUrl,
                };
              }),
            },
          },
        },
        {
          stepTitle: "Bid increment",
          stepFields: {
            bidIncrement: {
              type: "bidIncrement",
              value:
                bidIncrement.length > 0
                  ? bidIncrement.map((item) => {
                      return {
                        bidFrom: item.bidFrom,
                        bidTo: item.bidTo,
                        step: item.step,
                      };
                    })
                  : [
                      {
                        bidFrom: 0, //number
                        bidTo: 0, //number
                        step: 0, //number
                      },
                    ],
            },
          },
        },
      ];
      return formData;
    },
  },
};
</script>

<style></style>
