import AdminApi from "@/api/admin/AdminApi";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

export default class UploadFile extends AdminApi {
  constructor() {
    super();
  }

  async uploadFile(file, isAvatar, { uid, TTUId }) {
    let blob = file.slice(0, file.size, file.type);
    let tempFile = new File(
      [blob],
      "" + uid + "-" + uuidv4() + "-" + file.name,
      { type: file.type }
    );
    let type = file.type == "video/mp4" ? "video" : "photo";
    let formData = new FormData();
    formData.append("file", tempFile);
    const { data: { data } } = await axios.post(this.domain + "provisionnew/UploadMedia", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        "x-id": uid,
        "x-ttu-id": TTUId,
        "x-device-id": this.getXDeviceId(),
        "x-lang": this.getLang(),
        "x-token-id": await this.getToken(),
        "x-media-type": type,
        "x-user": uid,
        "x-upload-type": isAvatar,
        "x-session-type": uuidv4()
      }
    });
    return {
      ...data,
      type
    };
  }
}