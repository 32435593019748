import AdminApi from "@/api/admin/AdminApi";

export default class AdminPartnersApi extends AdminApi {
  constructor() {
    super();
    this.baseEndpoint = this.domain + "identityadm/v1.0/partners";
  }

  async getList(pagination) {
    let url = this.baseEndpoint;
    let response = await this.getData(url, pagination);
    return response;
  }

  async insertItem(item) {
    let url = this.baseEndpoint;
    let response = await this.insertOrUpdateData(url, item);
    return response;
  }

  async updateItem(item) {
    let url = this.baseEndpoint + `/${item.id}`;
    let response = await this.putData(url, item);
    return response;
  }

  async deleteItem(item) {
    let url = this.baseEndpoint + `/${item.id}`;
    let response = await this.deleteData(url, item, true);
    return response;
  }
}
