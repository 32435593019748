import AdminApi from "@/api/admin/AdminApi";

export default class AdminCommentModerationApi extends AdminApi {
  constructor() {
    super();
    this.baseEndpoint =
      this.domain + "identityadm/v1.0/reported-comment-moderation";
  }

  async getList(pagination) {
    let url = this.baseEndpoint;
    let response = await this.getData(url, pagination);
    return response;
  }

  async approveComment(payload) {
    const url = this.baseEndpoint + "/accept-report";
    const response = await this.patchData(url, payload);
    return response;
  }

  async rejectComment(commentID) {
    const url = this.baseEndpoint + "/reject-report";
    const response = await this.patchData(url, { commentID });
    return response;
  }
}
