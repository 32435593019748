import AdminApi from "@/api/admin/auctionBlockedUsers";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const errorHandling = new ErrorHandling();

const getItems = async ({ commit, state }) => {
  commit("setLoading", true);
  const options = JSON.parse(JSON.stringify(state.pagination));
  try {
    const { data } = await adminApi.getUserList(options);
    commit("setItemsData", data);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error, { pagination: options });
    throw new Error(error);
  }
};

const blockUser = async ({ commit }, payload) => {
  commit("setLoading", true);
  try {
    await adminApi.blockUser(payload);
    // commit("setItemsData", data);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error);
    throw new Error(error);
  }
};

const unblockUser = async ({ commit }, userId) => {
  commit("setLoading", true);
  try {
    await adminApi.unblockUser(userId);
    // commit("setItemsData", data);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error);
    throw new Error(error);
  }
};

const setPagination = async ({ commit }, payload) => {
  commit("setPagination", payload);
};

export default {
  getItems,
  blockUser,
  unblockUser,
  setPagination,
};
