<template>
  <v-container fluid>
    <div class="d-flex flex-column fill-height flex-grow-1">
      <div class="d-flex">
        <div class="text-h4">Post moderation</div>
      </div>
      <div class="flex-grow-1">
        <div class="filters-wrapper d-flex flex-column flex-md-row py-4">
          <div class="date-wrapper d-flex flex-column flex-md-row align-center">
            <Datepicker
              :namespace="namespace"
              :initial-value="sevenDaysAgo"
              :from-date="true"
            />
            <Datepicker
              :namespace="namespace"
              :initial-value="currentDate"
              :to-date="true"
            />
          </div>
          <div class="moderation-types-wrapper mt-4">
            <v-select
              v-model="selectValue"
              :items="moderationTypes"
              outlined
              :attach="'#menu'"
              hide-details
              label="Moderation type"
              placeholder="Moderation type"
              @change="changeModerationType"
            ></v-select>
            <div id="menu"></div>
          </div>
        </div>
        <t-table
          :headers="headers"
          :namespace="namespace"
          :permissions-namespace="namespace"
          :route-name="routeName"
          :id="idValue"
          :custom-action-btn="customActionBtn"
          @custom-action="viewPostInfo"
        />
      </div>
      <t-dialog-wrapper
        :show="postInfoModal"
        width="600"
        @close-dialog="closePostInfoModal"
      >
        <v-card>
          <v-card-title class="flex-column align-stretch px-0 pb-0">
            <div class="ml-4 mb-2">Post info</div>
            <v-divider></v-divider>
          </v-card-title>
          <v-card-text>
            <div class="text-h6 mt-4" v-if="!postInfo">Not available</div>
            <div v-else>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Post ID: </span
                >{{ postInfo.id || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Post caption: </span
                >{{ postInfo.caption || "-" }}
              </div>
              <post-media
                :post="postInfo"
                :media="postInfo.mediaItems"
                :height="mediaHeight"
              />
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Post mentions: </span
                >{{ postInfo.mentions || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Post location: </span
                >{{ postInfo.location || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">Moderation status: </span
                >{{ postInfo.moderationResultType || "-" }}
              </div>
              <div class="text-body-1 mt-4">
                <span class="font-weight-bold">User: </span
                >{{ postInfo.user.username || "-" }}
              </div>
            </div>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-5 actions-wrapper">
            <v-btn
              large
              text
              @click="approvePost(postInfo)"
              v-if="canApprove"
              :loading="loadingApprove"
            >
              Approve
            </v-btn>
            <v-btn
              large
              :loading="loadingReject"
              color="#da192f"
              class="white--text"
              @click="rejectPost(postInfo)"
              v-if="canReject"
            >
              Reject
            </v-btn>
          </v-card-actions>
        </v-card>
      </t-dialog-wrapper>
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "PostModeration",
  components: {
    TTable: () => import("@/components/Core/Table/TTable"),
    Datepicker: () => import("@/components/Core/Datepicker"),
    TDialogWrapper: () => import("@/components/Dialog/TDialogWrapper"),
    PostMedia: () => import("@/components/Admin/PostModeration/PostMedia"),
  },
  data() {
    return {
      loadingApprove: false,
      loadingReject: false,
      routeName: "post-moderation",
      namespace: "postModeration",
      idValue: "id",
      customActionBtn: {
        text: "View",
        icon: "mdi-card-search-outline",
      },
      postInfoModal: false,
      postInfo: null,
      headers: [
        {
          text: "Post ID",
          align: "left",
          value: "id",
          sortable: false,
        },
        {
          text: "Post caption",
          align: "left",
          value: "caption",
          sortable: false,
        },
        {
          text: "Post mentions",
          align: "left",
          value: "mentions",
          sortable: false,
        },
        {
          text: "Post location",
          align: "left",
          sortable: false,
          value: "location",
        },
        {
          text: "User",
          align: "left",
          sortable: false,
          value: "user.username",
        },
        {
          text: "Moderation status",
          align: "left",
          sortable: false,
          value: "moderationResultType",
        },
        {
          text: "View post",
          align: "center",
          sortable: false,
          value: "customAction",
        },
      ],
      dateFrom: "",
      dateTo: "",
      moderationTypes: [
        {
          text: "OK",
          value: 0,
        },
        {
          text: "Offensive",
          value: 1,
        },
        {
          text: "Need to review",
          value: 2,
        },
      ],
      selectValue: {
        text: "Need to review",
        value: 2,
      },
    };
  },
  mounted() {
    this.selectValue.value = this.options.moderationResult;
  },
  computed: {
    ...mapGetters({
      options: "postModeration/pagination",
    }),
    sevenDaysAgo() {
      const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
      return sevenDaysAgo.toLocaleString("fr-CA", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    },
    currentDate() {
      const newDate = new Date();
      return newDate.toLocaleString("fr-CA", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      });
    },
    canApprove() {
      return (
        this.options.moderationResult === 1 ||
        this.options.moderationResult === 2
      );
    },
    canReject() {
      return (
        this.options.moderationResult === 0 ||
        this.options.moderationResult === 2
      );
    },
    mediaHeight() {
      return this.$vuetify.breakpoint.mdAndUp ? "100%" : "300px";
    },
  },
  methods: {
    async changeModerationType() {
      this.$store.commit("postModeration/setModerationType", this.selectValue);
    },
    viewPostInfo(item) {
      this.postInfo = item;
      this.postInfoModal = true;
    },
    closePostInfoModal() {
      this.postInfoModal = false;
      this.postInfo = null;
    },
    async approvePost(post) {
      this.loadingApprove = true;
      await this.$store.dispatch("postModeration/approvePost", post.id);
      this.loadingApprove = false;
      this.postInfoModal = false;
    },
    async rejectPost(post) {
      this.loadingReject = true;
      await this.$store.dispatch("postModeration/rejectPost", post.id);
      this.loadingReject = false;
      this.postInfoModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.moderation-types-wrapper {
  position: relative;
}

#menu {
  position: absolute;
  bottom: 0px;
}
.filters-wrapper {
  padding: 12px;
}
</style>
