import AdminApi from "@/api/admin/countries";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const errorHandling = new ErrorHandling();

const getItems = async ({ commit, state }) => {
  commit("setLoading", true);
  const options = JSON.parse(JSON.stringify(state.pagination));
  Object.keys(options).forEach(function() {
    options["SortDesc"] = options["sortDesc"].toString();
    options["CountryName"] = options["Name"];
    options["PageSize"] = options["itemsPerPage"];
    options["PageNumber"] = options["page"];
  });
  try {
    const { data } = await adminApi.getList(options);
    commit("setItemsData", data);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error, { pagination: options });
    throw new Error(error);
  }
};

const setPagination = async ({ commit }, payload) => {
  commit("setPagination", payload);
};

const insertItem = async ({ commit, dispatch }, item) => {
  commit("setLoading", true);
  try {
    await adminApi.insertItem(item);

    dispatch(
      "snackbar/show",
      {
        text: "Success",
        color: "success",
      },
      { root: true }
    );
    dispatch("getItems");
    commit("setLoading", false);
  } catch (error) {
    dispatch(
      "snackbar/show",
      {
        text: "Country name, iso2 or iso3 already exist!",
        color: "error",
      },
      { root: true }
    );
    commit("setLoading", false);
    errorHandling.onError(error, { item });
  }
};

const updateItem = async ({ commit, dispatch }, item) => {
  commit("setLoading", true);
  try {
    await adminApi.updateItem(item);
    dispatch(
      "snackbar/show",
      {
        text: "Success",
        color: "success",
      },
      { root: true }
    );
    dispatch("getItems");
    commit("setLoading", false);
  } catch (error) {
    dispatch(
      "snackbar/show",
      {
        text: "Country name, iso2 or iso3 already exist!",
        color: "error",
      },
      { root: true }
    );
    commit("setLoading", false);
    errorHandling.onError(error, { item });
  }
};

const deleteItem = async ({ commit }, item) => {
  commit("setLoading", true);
  try {
    await adminApi.deleteItem(item);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error, { item });
    throw error;
  }
};

export default {
  getItems,
  setPagination,
  insertItem,
  updateItem,
  deleteItem,
};
