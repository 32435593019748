<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <t-form-wrapper
          v-if="auctionFormData"
          :form-data="auctionFormData"
          :callback-route="callbackRoute"
          :store-url="storeUrl"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  components: {
    TFormWrapper: () => import("@/components/Form/TFormWrapper")
  },
  name: "EditAuction",
  data() {
    return {
      auctionFormData: null,
      storeUrl: "adminAuctions/updateItem",
      callbackRoute: "list-auction"
    };
  },
  props: {
    uid: {
      type: [Number, String]
    }
  },
  async mounted() {
    await this.$store.dispatch("adminProducts/getItems", true);
    await this.$store.dispatch("adminSponsors/getItems", true);
    await this.$store.dispatch("adminAuctions/getItem", this.uid);
    this.auctionFormData = this.createFormData();
    this.loading = false;
  },
  computed: {
    ...mapGetters({
      auction: "adminAuctions/item",
      products: "adminProducts/items",
      sponsors: "adminSponsors/items"
    })
  },
  methods: {
    createFormData() {
      const {
        auctionId,
        name,
        description,
        startingBid,
        auctionProductId,
        auctionSponsorId,
        startDateTime,
        endDateTime
      } = this.auction;
      let formData = [
        {
          stepTitle: "General",
          stepFields: {
            auctionId: {
              type: "text",
              disabled: true,
              value: auctionId,
              placeholder: "Auction ID",
              label: "ID",
              required: true
            },
            name: {
              type: "text",
              value: name,
              placeholder: "Enter Auction name",
              label: "Name",
              required: true
            },
            startingBid: {
              type: "number",
              value: startingBid,
              name: "startingBid",
              placeholder: "Enter Auction starting bid",
              label: "Auction starting bid",
              required: true,
              minValue: 1,
              isInteger: true
            },
            description: {
              type: "textarea",
              value: description,
              placeholder: "Enter Auction Description",
              label: "Description",
              required: true
            },
            auctionProductId: {
              type: "select",
              value: auctionProductId,
              name: "auctionProductId",
              items: this.products,
              itemValue: "auctionProductId",
              itemText: "name",
              placeholder: "Select product",
              label: "Select product",
              required: true
            },
            auctionSponsorId: {
              type: "select",
              value: auctionSponsorId,
              name: "auctionSponsorId",
              items: this.sponsors,
              itemValue: "auctionSponsorId",
              itemText: "name",
              placeholder: "Select sponsor",
              label: "Select sponsor",
              required: false
            }
          }
        },
        {
          stepTitle: "Date and time",
          stepFields: {
            startDateTime: {
              type: "dateTime",
              label: "Auction start date and time",
              value: this.$moment
                .utc(startDateTime)
                .local()
                .format(),
              showTime: true,
              isRange: true,
              rangeLabel: "start"
            },
            endDateTime: {
              type: "dateTime",
              label: "Auction end date and time",
              value: this.$moment
                .utc(endDateTime)
                .local()
                .format(),
              showTime: true,
              isRange: true,
              rangeLabel: "end",
              rangeValid: true
            }
          }
        }
      ];
      return formData;
    }
  }
};
</script>

<style></style>
