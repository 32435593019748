import axios from "axios";

import Api from "./../Api";

export default class AdminApi extends Api {
	constructor() {
		super();
		this.domain = process.env.VUE_APP_API;
	}

	async setHeaders() {
		const token = await this.getToken();
		const headers = {
			"Content-Type": "application/json",
			Authorization: "Bearer " + token
		};
		return headers;
	}

	async getData(url, pagination = null) {
		let headers = await this.setHeaders();
		let data = pagination ? pagination : "";
		const response = await axios.get(url, {
			headers,
			params: data
		});
		return response;
	}

	async insertOrUpdateData(url, data) {
		let headers = await this.setHeaders();
		const response = await axios.post(url, data, {
			headers
		});
		return response;
	}

	async putData(url, data) {
		let headers = await this.setHeaders();
		const response = await axios.put(url, data, {
			headers
		});
		return response;
	}

	async patchData(url, data = null) {
		let headers = await this.setHeaders();
		const response = await axios.patch(url, data, {
			headers
		});
		return response;
	}

	async deleteData(url, data, deleteMethod = null) {
		let headers = await this.setHeaders();
		let response = null;
		if (deleteMethod) {
			response = await axios.delete(url, {
				headers
			});
		} else {
			response = await axios.post(url, data, {
				headers
			});
		}
		return response;
	}
}
