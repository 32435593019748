import AdminApi from "@/api/admin/commentModeration";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const errorHandling = new ErrorHandling();

const getItems = async ({ commit, state }) => {
  commit("setLoading", true);
  const options = JSON.parse(JSON.stringify(state.pagination));
  Object.keys(options).forEach(function() {
    options["pageNumber"] = options["page"];
    options["pageSize"] = options["itemsPerPage"];
    options["searchTerm"] = options["Name"];
  });
  try {
    const { data } = await adminApi.getList(options);
    commit("setItemsData", data);
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error, { pagination: options });
    throw new Error(error);
  }
};

const setPagination = async ({ commit }, payload) => {
  commit("setPagination", payload);
};

const approveComment = async ({ commit, dispatch }, payload) => {
  commit("setLoading", true);
  try {
    await adminApi.approveComment(payload);
    await dispatch("getItems");
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error);
    throw new Error(error);
  }
};

const rejectComment = async ({ commit, dispatch }, commentId) => {
  commit("setLoading", true);
  try {
    await adminApi.rejectComment(commentId);
    await dispatch("getItems");
    commit("setLoading", false);
  } catch (error) {
    commit("setLoading", false);
    errorHandling.onError(error);
    throw new Error(error);
  }
};

export default {
  getItems,
  setPagination,
  approveComment,
  rejectComment,
};
