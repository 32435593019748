import Vue from "vue";
const setItemsData = (state, value) => {
  value.items.map((item) => {
    item.blockedBy = item.blockedBy === 0 ? "Admin" : "Auction win";
    item.blockedUntil = new Date(item.blockedUntil).toLocaleString("fr-CA", {
      year: "numeric",
      month: "numeric",
      day: "numeric",
    });
  });

  state.items = value.items;
};

const setLoading = (state, value) => {
  state.loading = value;
};

const setPagination = (state, value) => {
  Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

const setFromDate = (state, value) => {
  state.pagination.StartDateFrom = value;
};

const setToDate = (state, value) => {
  state.pagination.StartDateTo = value;
};

const setBlockedByType = (state, value) => {
  state.pagination.BlockedBy = value;
  state.pagination.page = 1;
};

export default {
  setItemsData,
  setPagination,
  setLoading,
  setFromDate,
  setToDate,
  setBlockedByType,
};
