import AdminApi from "@/api/admin/reports";
import ErrorHandling from "@/plugins/ErrorHandling";

const adminApi = new AdminApi();
const errorHandling = new ErrorHandling();

const generateFraudUserReport = async ({ commit }, reportPayload) => {
	commit("setLoading", true);
	try {
		const response = await adminApi.generateFraudUserReport(reportPayload);
		commit("setLoading", false);
		return (response && response.status === 200)
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error, { reportPayload });
	}
};

const generateRegisteredUsersReport = async ({ commit }, reportPayload) => {
	commit("setLoading", true);
	try {
		const response = await adminApi.generateRegisteredUsersReport(reportPayload);
		commit("setLoading", false);
		return (response && response.status === 200)
	} catch (error) {
		commit("setLoading", false);
		errorHandling.onError(error, { reportPayload });
	}
};

export default {
	generateFraudUserReport,
	generateRegisteredUsersReport,
};
