import Vue from "vue";
const setSummaryData = (state, value) => {
  state.summary = value.result ? value.result : state.summary;
};

const setItems = (state, value) => {
  state.items = value.result ? value.result : state.items;
};

const setLoadingSummary = (state, value) => {
  state.loadingSummary = value;
};

const setLoading = (state, value) => {
  state.loading = value;
};

const setPagination = (state, value) => {
  Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

// const setFromDate = (state, value) => {
//   state.pagination.fromDate = value;
// };

// const setToDate = (state, value) => {
//   state.pagination.toDate = value;
// };

// const setReason = (state, value) => {
//   state.pagination.reportReason = value;
//   state.pagination.page = 1;
// };

export default {
  setSummaryData,
  setItems,
  setLoading,
  setLoadingSummary,
  setPagination,
};
