<template>
  <v-card>
    <v-card-title>Latest Signups</v-card-title>
    <v-card-text>
      <user-table :items="latestSignups" />
    </v-card-text>
  </v-card>
</template>

<script>
import UserTable from "./UserTable.vue";
import { mapGetters } from "vuex";
export default {
  name: "DashboardLatestSignups",
  components: {
    UserTable,
  },
  props: {
    latestSignups: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      loading: "adminUsers/loading",
    }),
  },
};
</script>

<style></style>
