import UploadFileApi from "@/api/admin/uploadFile";
import ErrorHandling from "@/plugins/ErrorHandling";

const uploadFileApi = new UploadFileApi();
const errorHandling = new ErrorHandling();

const uploadFile = async ({ rootGetters }, { file, isAvatar }) => {
  try {
    const { TTUId, uid } = rootGetters["auth/authUser"];
    const response = await uploadFileApi.uploadFile(file, isAvatar, {
      TTUId,
      uid,
    });
    return response;
  } catch (error) {
    errorHandling.onError(error, { file, isAvatar });
  }
};

export default {
  uploadFile,
};
