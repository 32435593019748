<template>
  <v-data-table
    :dense="dense"
    :headers="headers"
    @click:row="click($event)"
    :items="items"
    :items-per-page="itemsPerPage"
    show-expand
    item-key="date"
    :loading="loading"
  >
    <template v-slot:item.picture="{ item }">
      <avatar class="mx-auto" :user="item" />
    </template>
    <template v-slot:item.date="{ item }">
      <moments-ago :unix="true" :date="item.date" />
    </template>
    <template v-slot:item.result="{ item }">
      {{ item.result === "OK" ? "OK" : readError(item.reason) }}
    </template>
    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length" class="py-2">
        <div>First Name: {{ item.firstName }}</div>
        <div>Last Name: {{ item.lastName }}</div>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
    },
    dense: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    MomentsAgo: () => import("@/components/Core/MomentsAgo"),
    Avatar: () => import("@/components/Core/Avatar"),
  },
  data() {
    return {
      itemsPerPage: 5,
      headers: [
        {
          text: "Avatar",
          value: "picture",
          align: "center",
          sortable: false,
        },
        {
          text: "E-mail",
          align: "center",
          value: "email",
        },
        {
          text: "Username",
          align: "center",
          value: "userName",
        },
        {
          text: "Provider",
          align: "center",
          value: "provider",
        },
        {
          text: "When",
          align: "center",
          value: "date",
        },
        {
          text: "Status",
          align: "center",
          value: "result",
        },
        { text: "", value: "data-table-expand" },
      ],
    };
  },
  computed: {
    loading() {
      return this.items.length === 0;
    },
  },
  methods: {
    click(item) {
      if (item.userId)
        this.$router.push({
          name: "show-user",
          params: { uid: item.userId },
        });
    },
    readError(val) {
      return val;
    },
  },
};
</script>
