import AdminRoleGroups from "@/components/Admin/RoleGroups/List";
import CreateRoleGroup from "@/components/Admin/RoleGroups/Create";
import EditRoleGroup from "@/components/Admin/RoleGroups/Edit";

export default [
	{
		path: "role-group",
		name: "list-role-group",
		component: AdminRoleGroups
	},
	{
		path: "create-role-group",
		name: "create-role-group",
		component: CreateRoleGroup
	},
	{
		path: "edit-role-group/:uid",
		name: "edit-role-group",
		props: true,
		component: EditRoleGroup
	}
];
