import Vue from "vue";
const setItemsData = (state, value) => {
	state.items = value.result ? value.result : state.items;
	state.totalCount = value.pagination
		? value.pagination.totalCount
		: state.totalCount;
};

const setItemData = (state, value) => {
	state.item = value.result;
};

const setLoading = (state, value) => {
	state.loading = value;
};

const setPagination = (state, value) => {
	Vue.set(state, "pagination", Object.assign(state.pagination, value));
};

export default {
	setItemsData,
	setItemData,
	setPagination,
	setLoading
};
