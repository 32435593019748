const items = (state) => {
  state.items.forEach((item) => {
    // if (item.post.mediaItems && item.post.mediaItems.length > 0) {
    //   item.media = { src: item.post.mediaItems[0].mediaEndpoint, type: item.post.mediaItems[0].type }
    // }
    if (item.reports && item.reports.length > 0) {
      let expandedRow = `Reported by<br/>`
      item.reports.map((report) => {
        expandedRow += 
          'User: <b>' 
          + (report.user ? report.user.username : '') 
          + '</b> | Reason: <b>' 
          + report.reason.reportName 
          + '</b><br/>'
      })
      item.expandedRow = expandedRow
    }
  });
  return state.items;
};
const loading = (state) => state.loading;
const pagination = (state) => state.pagination;
const totalCount = (state) => state.totalCount;

export default {
  items,
  loading,
  totalCount,
  pagination,
};
